import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { Heading } from '../../atoms/Heading';
import { Switch } from '../../atoms/Switch';
import { headingType, size, tags } from '../../utils/enums/enums';
import { ToggleControlsListProps, ControlsProps } from './ToggleControlsList.types';
import { StyledToggleControlsList } from './ToggleControlsList.styles';

const renderControl = (
  control: ControlsProps,
  selectedControls: ControlsProps[],
  handleControlToggle: (control: ControlsProps) => void,
  name: string,
  index: number,
  custom_click_track_value: string
) => {
  const controlId = `${name.replace(/ /g, '')}-${index}`;

  return (
    <Switch
      key={index}
      toggleId={controlId}
      toggleName={name}
      selected={selectedControls.some((item: ControlsProps) => item?.label === control?.label)}
      onChange={() => handleControlToggle(control)}
      setTabIndex={0}
      value={control?.code || control?.label || ''}
      className="toggle-controls-list__toggle col-md-6 col-sm-12 pb-3"
      inputClassName="checkbox-input custom_click_track"
      children={
        <span className="t-font-s pl-1">
          {control?.count ? control?.label + ` (${control?.count})` : control?.label ? control?.label : ''}
        </span>
      }
      custom_click_track_value={custom_click_track_value}
    />
  );
};

export const ToggleControlsList: React.FC<ToggleControlsListProps> = ({
  title,
  subTitle = '',
  eyebrowText = '',
  controls,
  enableShowMoreToggle = false,
  showMoreCount = 0,
  onChange,
  defaultSelected = [],
  name,
  showMoreLabel = 'Show More',
  showLessLabel = 'Show Less',
  showSeparator = false,
  custom_click_track_value = '',
}) => {
  const [selectedControls, setSelectedControls] = useState<ControlsProps[]>(defaultSelected);
  const [showMore, setShowMore] = useState(true);

  useEffect(() => {
    setSelectedControls(defaultSelected);
  }, [defaultSelected]);

  const handleControlToggle = (control: ControlsProps) => {
    let updatedSelection: ControlsProps[] = [];
    if (selectedControls.some((item: ControlsProps) => item.label === control?.label)) {
      updatedSelection = selectedControls.filter(ctl => ctl.label !== control?.label);
    } else {
      updatedSelection.push(...selectedControls, control);
    }

    setSelectedControls(updatedSelection);
    onChange(updatedSelection);
  };

  const renderedControls = !enableShowMoreToggle || !showMore ? controls : controls.slice(0, showMoreCount);
  const btnLabel = !showMore ? showLessLabel : showMoreLabel;

  return (
    <StyledToggleControlsList className={`col-12 toggle-controls-list`}>
      <fieldset>
        {title && (
          <div className="my-4 mt-md-5 mb-md-4 mt-lg-5 mb-lg-4 mt-xl-5 mb-xl-4">
            <legend>
              <Heading
                variation={headingType.subtitle}
                titleText={title}
                fontSize={size.large}
                element={tags.h4}
                customClass={'toggle-controls-list__title'}
              ></Heading>
            </legend>
          </div>
        )}
        {subTitle && (
          <Heading
            variation={headingType.subtitle}
            titleText={subTitle}
            fontSize={size.large}
            element={tags.h5}
            customClass={'pt-0 pb-3'}
          ></Heading>
        )}
        {eyebrowText && <p className="t-label-xs filter-sub-heading mb-0 pb-3 pt-0">{eyebrowText}</p>}
        <div className="row">
          {renderedControls.map((control, index) =>
            renderControl(control, selectedControls, handleControlToggle, name, index, custom_click_track_value)
          )}
        </div>
        {enableShowMoreToggle && controls.length > showMoreCount && (
          <button className="show-more-btn t-font-s p-0" onClick={() => setShowMore(!showMore)}>
            {btnLabel}{' '}
            <span
              className={clsx('show-more-icon', { 'icon-dropdown-down': showMore, 'icon-dropdown-up': !showMore })}
            ></span>
          </button>
        )}
        {showSeparator && <hr className="filter-separator mt-4 mt-md-5 mt-lg-5 mt-xl-5" />}
      </fieldset>
    </StyledToggleControlsList>
  );
};
