import { FC } from 'react';
import { CheckBoxProps } from './CheckBox.types';
import { StyledCheckBoxDiv } from './CheckBox.styles';
import { Icon } from '../Icon';

export const CheckBox: FC<CheckBoxProps> = ({
  className,
  checkboxId,
  checkboxName,
  checkboxLabel,
  checked,
  children,
  value,
  inputClassName,
  onChange,
  onKeyDown,
  disabled = false,
  iconClassName,
  ...props
}) => {
  return (
    // Minimum Attributes Required on checkbox are:
    // id, name, onChange, Checked, value, className
    // two types of chekcboxes: simple and switch. If we don't pass variation prop, by default, simple checkbox will be rendered.
    <StyledCheckBoxDiv className={className}>
      <>
        <input
          type="checkbox"
          id={checkboxId}
          name={checkboxName}
          onChange={onChange}
          onKeyDown={onKeyDown}
          checked={checked}
          value={value}
          className={inputClassName}
          aria-checked={checked}
          disabled={disabled}
          {...props}
        ></input>
        <label htmlFor={checkboxId}>
          {iconClassName ? <Icon iconClass={`${iconClassName} pr-1`} /> : ''}
          {checkboxLabel}
          {children}
        </label>
      </>
    </StyledCheckBoxDiv>
  );
};
