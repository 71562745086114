/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, forwardRef, useRef, useImperativeHandle, useState, FC, useEffect } from 'react';
import DayPicker, { DayModifiers } from 'react-day-picker';
import { CalendarProps } from './Calendar.types';
import { StyledCalendarDiv, StyledFooterDiv } from './Calendar.styles';
import { getUserAgentType } from '../../styles/utils';
import { useCalendar, VARIATION_VERTICAL, VARIATION_TABLET } from './Calendar.controller';
import 'react-day-picker/lib/style.css';
import moment from 'moment';

/**
 *
 * @param props
 * variatuon = "DESKTOP"|MOBILE|TABLET on basis of variation it will render'
 // startDate: moment | date | nulll
//   endDate: Moment | null;
//   focusedInput: "START_DATE"|"END_DATE" | null;
//   setDateHandler * used to get the seleted date range
//   setFocusedInputHandler: current <focused input>;
//   nightsLabel: "nights" text string for tooltip label;
//   nightLabel: single night label;
//   maxAllowedDays: number;
//   weekdays: weekdays arre <[sunday,monday....]>
//   firstDayOfWeek: starting day for the week <0 for sunday>;
// }
 * @return
* calendar for range date picker
 */
//TODO: Need to remove this any keyword from here
export const Calendar = forwardRef<any, CalendarProps>((props: CalendarProps, ref: any) => {
  const calendarRef = useRef(null) as any;
  const {
    onDateSelect,
    className,
    singleDatePicker,
    variation,
    dateRef,
    getVerticalMonths,
    numberOfNightsValue,
    selectedDaysModifier,
    selectedDaysRange,
    onDayMouseLeave,
    onDayClickHandler,
    fromMonth,
    toMonth,
    renderDay,
    weeksdaysShort,
    monthsValue,
    weekdaysLongName,
    defaultMonth,
    disabledDays,
    updateMouseEnterEvent,
    firstDayOfWeek,
    numberOfMonths,
    showOutsideDays = true,
    fixedWeeks,
    customRenderDayCell,
    setEndFromDatesForcefully,
    displayFooterSection = false,
    footerItems,
    footerBackGround,
    resetCalendarDates,
    enableOutsideDaysClick = false,
    isPastDateSelectionDisabled = true,
    captionElement,
  } = useCalendar(props);
  const [month, setMonth] = useState(defaultMonth);
  const tempArray: Date[] = [];
  props?.disabledDays?.forEach(item => {
    tempArray?.push(new Date(item));
  });

  const finalDisabledArray = [
    ...tempArray,
    {
      from: new Date(moment().subtract(1, 'M').startOf('M').format()),
      to: new Date(moment().subtract(1, 'd').format()),
    },
  ];

  useImperativeHandle(
    ref,
    () => {
      /**
       * can pass the custom ref function to calendar
       */
      return {
        /**
         * forcefully we have to change the selected range with the help of ref
         */
        setFromEndDate(startDate: Date | null, endDate: Date | null) {
          setEndFromDatesForcefully(startDate, endDate);
        },
        setDefaultMonth(date: Date) {
          setMonth(date);
        },
        resetCalendarDates() {
          resetCalendarDates();
        },
      };
    },
    []
  );

  useEffect(() => {
    if (calendarRef?.current) {
      // remove tabindex from calendar wrapper to fix accessibility issues
      calendarRef?.current?.dayPicker?.firstChild.removeAttribute('tabindex');

      /** update header aria-level for heading arc toolkit isssue */
      const monthEl = calendarRef?.current?.wrapper?.querySelectorAll('.DayPicker-Caption');
      monthEl?.forEach((element: any) => {
        element?.setAttribute('aria-level', '4');
      });
    }
  }, []);

  const FooterComponent: FC = () => (
    <StyledFooterDiv backGround={footerBackGround}>
      <div className="footer-component d-flex flex-row-reverse flex-wrap justify-content-between mx-5 mb-3">
        {footerItems?.map(element => element)}
      </div>
    </StyledFooterDiv>
  );

  return (
    <>
      <StyledCalendarDiv
        disableHover={props.disableHover}
        variation={variation}
        ref={dateRef}
        userAgentType={getUserAgentType()}
        data-testid="day-picker"
        className={className}
      >
        <DayPicker
          ref={calendarRef}
          className="DayPicker-wrapper-react-el"
          selectedDays={selectedDaysRange}
          modifiers={selectedDaysModifier}
          {...(variation === VARIATION_VERTICAL
            ? { initialMonth: fromMonth }
            : { month: props?.advanceMonth ? props.defaultMonth : month })}
          fromMonth={fromMonth}
          toMonth={toMonth}
          weekdaysShort={weeksdaysShort}
          weekdaysLong={weekdaysLongName}
          months={monthsValue}
          {...(isPastDateSelectionDisabled && {
            disabledDays: props.advanceMonth ? finalDisabledArray : disabledDays,
          })}
          captionElement={captionElement}
          firstDayOfWeek={Number(firstDayOfWeek) || 0}
          showOutsideDays={showOutsideDays}
          enableOutsideDaysClick={enableOutsideDaysClick}
          fixedWeeks={fixedWeeks}
          renderDay={(day, _modifier): ReactNode => {
            /**
             * use custom render function to render the cell
             * it is used for to render the price and other labels
             * byt default it will use calendar render day function if we will not pass this
             */
            return customRenderDayCell ? customRenderDayCell(day) : renderDay(day);
          }}
          numberOfMonths={
            numberOfMonths
              ? numberOfMonths
              : variation === VARIATION_VERTICAL
              ? getVerticalMonths()
              : variation === VARIATION_TABLET
              ? 1
              : 2
          }
          onDayClick={(day, modifiers: DayModifiers): void => {
            if (singleDatePicker && day) {
              onDateSelect(day, modifiers);
            } else {
              // handle all the select event on day click
              onDayClickHandler(day, modifiers);
            }
          }}
          onDayMouseLeave={(_day: Date, modifiers: DayModifiers, _event: React.MouseEvent<HTMLDivElement>): void => {
            // handle mouse blur event
            onDayMouseLeave(modifiers);
          }}
          onMonthChange={(_month: Date): void => {
            props?.onMonthChange?.(_month);
            setMonth(_month);
            updateMouseEnterEvent(true);
          }}
        />
        {/* display tooltip for desktop varition */}
        {props.disableHoverToolTip ? '' : numberOfNightsValue()}
      </StyledCalendarDiv>
      {displayFooterSection && <FooterComponent />}
    </>
  );
});
