import React, { FC } from 'react';
import { Marker } from '@react-google-maps/api';

import { MapMarkerProps } from './MapMarker.types';
import { StyledMapMarker } from './MapMarker.styles';

// Use named rather than default exports.
export const MapMarker: FC<MapMarkerProps> = ({ ...props }) => {
  return (
    <StyledMapMarker>
      <Marker {...props}></Marker>
    </StyledMapMarker>
  );
};
