import moment from 'moment';
import { canUseDOM } from '../helpers';
import Cookies from 'js-cookie';
import { COOKIE_KEYS } from '../constants';
import { DATA_LAYER_ATTRIBUTES as datalayerAttrList } from '../constants';

export const getCurrentDateTime = () => {
  return moment(Date()).format('MM/DD/YYYY h:mm:ss');
};

export const getCurrentLocale = () => {
  if (canUseDOM) {
    return window.__NEXT_DATA__.props?.['pageProps']?.['currentLocale'] || 'en-US';
  }
};

export const propertyResolver = (value: string | undefined) => {
  return value ? value : '';
};

export const getEnvServerId = () => {
  if (Cookies.get(COOKIE_KEYS.miCnSite)) {
    return propertyResolver(Cookies.get(COOKIE_KEYS.miCnSite));
  } else {
    return propertyResolver(Cookies.get(COOKIE_KEYS.miSite));
  }
};

export const getCountryFromLocale = () => {
  const currentLocale = getCurrentLocale();
  return currentLocale.split('-')?.[1] || '';
};

export const getEnvSiteId = () => {
  const currentCountry = getCountryFromLocale();
  if (currentCountry === 'LACA') {
    return 'ES';
  } else return currentCountry;
};

export const getAkamaiLocationAttrs = (headerValue?: string) => {
  if (headerValue?.length) {
    return headerValue.split(',').reduce((acc: Record<string, string>, pair) => {
      const [key, value] = pair.split('=');
      acc[key] = value;
      return acc;
    }, {});
  } else
    return {
      country_code: '',
      lat: '',
      long: '',
      city: '',
      region_code: '',
    };
};

export const getDatalayerAttrList = () => {
  if (canUseDOM) {
    return window.__NEXT_DATA__.props?.['pageProps']?.['model']?.['dataLayerStaticElements'] || datalayerAttrList;
  } else {
    return datalayerAttrList;
  }
};
