import { useEffect } from 'react';
import { logger } from '../lib/logger';
import { useSession } from './use-session';
import { useDatalayer } from './use-datalayer';
import { getDatalayerObject } from '../lib/dataLayer/datalayerUtil';
import { getEnvProp } from '../lib/helpers';

export function useACDL() {
  const { log } = logger({})('useACDL');
  const { session } = useSession(); // init session store
  const { pushState } = useDatalayer();

  useEffect(() => {
    log.info('ACDL init!');
    if (getEnvProp('NEXT_PUBLIC_ENABLE_WEBSDK') === 'true') {
      pushState({
        event: 'dataLayerLoaded',
        data: getDatalayerObject(session),
      });
    }
  }, []);

  return null;
}
