export const DESTINATION_STATE = 'destination';
export const CALENDAR_STATE = 'calendar';
export const ROOM_GUEST_STATE = 'roomGuest';
export const SPECIAL_RATES_STATE = 'specialRates';
export const FORM_STATE = 'form';
export const CLOSED_STATE = 'closed';
export const START_DATE = 'startDate';
export const END_DATE = 'endDate';

export const RateRequestTypeOptions = {
  // used to apply discount in api call
  s9r: 'CLUSTER',
  corp: 'CLUSTER',
  gov: 'GOV',
  aaa: 'AAA',
};
export const hardCodedAllLocale = {
  de: 'de-DE',
  en: 'en-US',
  ja: 'ja-JP',
  fr: 'fr-FR',
};
