import clsx from 'clsx';
import React, { FC } from 'react';
import { LabelProps } from './Label.types';
import { tags } from '../../utils/enums/enums';

export const Label: FC<LabelProps> = ({ labelText, element = tags.div, size, customClass, variation }) => {
  const Tag = element;
  let labelClass;
  if (size && variation) {
    labelClass = `t-label-${variation}-${size}`;
  } else if (size) {
    labelClass = `t-label-${size}`;
  }
  return <Tag className={clsx(labelClass, customClass)}>{labelText}</Tag>;
};
