import React, { FC } from 'react';
import { SearchFieldProps } from './SearchField.types';
import { StyledSearchField } from './SearchField.styles';
import { SearchFieldCategories } from './SearchFieldCategories';

export const SearchField: FC<SearchFieldProps> = ({
  onCancel,
  onChange: changeMobileState,
  isModalOpen,
  isDesktopView,
  mobileModalHeading,
  mobilePlaceHolderText,
  hideClearCta = false,
  isMeetingDestField,
  isMobileForm,
}) => {
  return (
    <StyledSearchField data-testid="search-field-test-id">
      <SearchFieldCategories
        isDesktopView={isDesktopView}
        isModalOpen={isModalOpen}
        changeMobileState={changeMobileState}
        onCancel={onCancel}
        mobileModalHeading={mobileModalHeading}
        mobilePlaceHolderText={mobilePlaceHolderText}
        hideClearCta={hideClearCta}
        isMeetingDestField={isMeetingDestField}
        isMobileForm={isMobileForm}
      />
    </StyledSearchField>
  );
};
