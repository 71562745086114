import React from 'react';
import clsx from 'clsx';
import { EyebrowProps, EyebrowEnum } from './Eyebrow.types';

export const Eyebrow: React.FC<EyebrowProps> = ({ text, variation = 'normal', customClass, ...props }) => {
  const className = variation === ('normal' as EyebrowEnum.Default) ? 't-overline-normal' : 't-overline-overlay';

  return (
    <span className={clsx(className, customClass)} {...props}>
      {text}
    </span>
  );
};
