import { useDatalayer } from './use-datalayer';
import { SessionData, UserSessionActions, UserSessionState, useUserSessionStore } from '../lib/session';
import { getDatalayerObject } from '../lib/dataLayer/datalayerUtil';

export function useSession() {
  const session = useUserSessionStore((state: UserSessionState & UserSessionActions) => state.session);
  const updateSessionStore = useUserSessionStore((state: UserSessionState & UserSessionActions) => state.updateSession);
  const { pushState } = useDatalayer();

  const updateSession = (sessionData: SessionData) => {
    // push to dataLayer with latest session changes
    pushState({
      event: 'dataLayerUpdated',
      data: getDatalayerObject(sessionData),
    });
    updateSessionStore(sessionData);
  };

  return { session, updateSession };
}
