/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { StyledSection } from './styles';
import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';

const HTLWrapper: FC = (props: any) => {
  const { htlComponentHtml } = props;
  return (
    <StyledSection data-component-name="o-common-HTLWrapper">
      <div
        dangerouslySetInnerHTML={{ __html: htlComponentHtml }}
        className={`${AuthoringUtils.isInEditor() ? 'authorBorder' : ''}`}
      />
    </StyledSection>
  );
};

export const EditableHTLWrapperConfig = {
  emptyLabel: 'EditableHTLWrapper',
  isEmpty: false,
  resourceType: `mi-aem-shop/components/foundation`,
};

export const EditableHTLWrapper = (props: any) => (
  <EditableComponent config={EditableHTLWrapperConfig} {...props}>
    <HTLWrapper />
  </EditableComponent>
);
