import React, { ChangeEvent, FC } from 'react';
import { RichTextProps } from './RichText.types';
import { StyledRichText } from './RichText.styles';
import clsx from 'clsx';

export const RichText: FC<RichTextProps> = props => {
  const { text, componentId, styleclass, customClass, contentEditable, onChange, ...customProps } = props;
  const handleContentChange = (event: ChangeEvent<HTMLDivElement>) => {
    onChange?.(event);
  };

  return (
    <StyledRichText id={componentId} className={clsx(styleclass ? styleclass : '', customClass)}>
      {text && (
        <div
          contentEditable={contentEditable}
          dangerouslySetInnerHTML={{ __html: text }}
          onInput={handleContentChange}
          data-testid={componentId}
          {...customProps}
        ></div>
      )}
    </StyledRichText>
  );
};
