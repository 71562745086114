/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { StyledTab } from './TabContainer.styles';
import { Button, Icon, Heading, headingType, size, RichText } from '@marriott/mi-ui-library';
import { TabProps, TabDetails } from './TabContainer.types';
import { clsx } from 'clsx';
import Glide from '@glidejs/glide';

export const TabContainer = (props: TabProps) => {
  const tabProps = props;
  const {
    componentId,
    trackingProperties,
    tabDetails,
    headerTag,
    header,
    headerFontSize,
    subHeader,
    subHeaderTag,
    subFontSize,
    descriptionText,
    descFontSize,
    contentBlockAlignment,
    layoutContainer,
    children,
    isEditMode = false,
    styleclass,
    customClass,
  } = tabProps;

  const getTabWithLongestName = (tabDetails: TabDetails[]): TabDetails | undefined => {
    if (!tabDetails?.length) return undefined;

    const cleanTabName = (tab: TabDetails): TabDetails => ({
      ...tab,
      tabName: tab.tabName?.replace(/<p>|<\/p>|\r\n/g, '') || '',
    });

    const processedTabs = tabDetails.map(cleanTabName);

    const initialLongest = { tabName: '', tabIcon: '' };

    const longestTab = processedTabs.reduce((longest: TabDetails, current: TabDetails | undefined) => {
      if (current) {
        const currentLength = current.tabName?.length ?? 0;
        return currentLength > (longest.tabName?.length ?? 0) ||
          (currentLength === (longest.tabName?.length ?? 0) && (current?.tabName || '') > (longest.tabName || ''))
          ? current
          : longest;
      }
      return longest;
    }, initialLongest);

    return longestTab;
  };

  const [cardsPerView, setCardsPerView] = useState(2);
  const childrenArray = React.Children.toArray(children).filter(Boolean);
  const [activeStates, setActiveStates] = useState(new Array(childrenArray?.length).fill(false));
  const [activeTab, setActiveTab] = useState(0);
  const isAlternate = styleclass?.includes('alternate');
  const isInverse = styleclass?.includes('inverse');
  const arrowStyle = isAlternate ? 'alt-arrow' : isInverse ? 'inv-arrow' : '';
  const descriptionTextRef = useRef<HTMLDivElement>(null);
  const tabNameTextRef = useRef<HTMLDivElement>(null);
  const isSlideArrowsVisible = tabDetails?.length > cardsPerView;
  const borderEnabled = layoutContainer === 'with_border';
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isEditMode) {
      setActiveStates(new Array(childrenArray?.length).fill(true));
    } else {
      setActiveStates(new Array(childrenArray?.length).fill(false).map((_, index) => index === activeTab));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, activeTab, children?.length]);

  const handleTabClick = (tabidx: number) => {
    setActiveTab(tabidx);
    setActiveStates(prevStates => prevStates.map((_, index) => index === tabidx));
  };

  const handleResize = () => {
    const divWidth = containerRef?.current?.offsetWidth || 0;
    const longestTab = getTabWithLongestName(tabDetails);
    const longestTabWidth = (longestTab?.tabName?.length || 0) * 10;
    const tabsInAView = Math.floor(divWidth / (longestTabWidth > 80 ? longestTabWidth : 80));
    setCardsPerView(tabsInAView);
  };

  useEffect(() => {
    const carouselRootSelector = `#${componentId}`;
    let glide: Glide.Properties | Glide.Static;
    const leftArrow = document?.querySelector(`#${componentId}_left_arrow`);
    const rightArrow = document?.querySelector(`#${componentId}_right_arrow`);
    const preventDefault = (e: any) => e.preventDefault();
    if (document?.querySelector(carouselRootSelector)) {
      try {
        glide = new Glide(carouselRootSelector, {
          type: 'slider',
          perView: cardsPerView,
          startAt: 0,
          rewind: false,
          bound: true,
          gap: 1,
        }).mount({ ArrowDisabler });

        leftArrow?.addEventListener('touchstart', preventDefault);
        rightArrow?.addEventListener('touchstart', preventDefault);
      } catch (error) {
        // log.error(`Error in initializing Glide for ${componentId}`, error);
      }
    }
    return () => {
      if (glide) {
        (glide as Glide.Properties)?.destroy();
      }
      leftArrow?.removeEventListener('touchstart', preventDefault);
      rightArrow?.removeEventListener('touchstart', preventDefault);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentId, cardsPerView]);

  const ArrowDisabler = function (Glide: any, Components: any) {
    return {
      mount() {
        // Only in effect when rewinding is disabled
        if (Glide.settings.rewind) {
          return;
        }
        const left = document?.querySelector('.glide__arrow--left');

        left?.classList.remove('is-disabled');
        Glide.on(['mount.after', 'run'], () => {
          // Filter out arrows_control
          for (const controlItem of Components.Controls.items) {
            if (controlItem.className !== 'glide__arrows') {
              continue;
            }

            // Set left arrow state
            const left = controlItem?.querySelector('.glide__arrow--left');
            if (left) {
              if (Glide.index === 0) {
                left.classList.add('is-disabled'); // Disable on first slide
              } else {
                left.classList.remove('is-disabled'); // Enable on other slides
              }
            }

            // Set right arrow state
            const right = controlItem?.querySelector('.glide__arrow--right');
            if (right) {
              if (Glide.index === Components.Sizes?.length - Glide.settings.perView) {
                right.classList.add('is-disabled'); // Disable on last slide
              } else {
                right.classList.remove('is-disabled'); // Enable on other slides
              }
            }
          }
        });
      },
    };
  };

  const headerText = () =>
    header && (
      <Heading
        customClass={clsx('mb-0')}
        variation={headingType.title}
        fontSize={
          headerFontSize?.[headerFontSize?.length - 1] as
            | size.small
            | size.medium
            | size.large
            | size.extraSmall
            | undefined
        }
        element={headerTag}
        dangerouslySet={header}
      />
    );

  const subHeaderText = () =>
    subHeader && (
      <Heading
        element={subHeaderTag}
        variation={headingType.subtitle}
        customClass={clsx(!header ? 'mt-3' : '', 'mb-0')}
        dangerouslySet={subHeader}
        fontSize={
          subFontSize?.[subFontSize?.length - 1] as size.small | size.medium | size.large | size.extraSmall | undefined
        }
      />
    );

  const descriptionBlock = () =>
    descriptionText && (
      <RichText
        text={descriptionText}
        ref={descriptionTextRef}
        componentId={componentId + '-TabContainerDescriptionText'}
        customClass={clsx(!subHeader && !header ? 'mt-3' : '', descFontSize)}
      />
    );

  const TabContainerHeader = () => {
    return (
      <div
        className={clsx(
          'tab-container-header-section d-flex flex-column',
          contentBlockAlignment === 'center' ? 'align-items-center text-center' : '',
          contentBlockAlignment === 'left' ? 'align-items-start' : ''
        )}
      >
        {headerText()}
        {subHeaderText()}
        {descriptionBlock()}
      </div>
    );
  };

  return (
    <StyledTab className={clsx(styleclass, customClass)}>
      <div className="container px-0" ref={containerRef}>
        {TabContainerHeader()}
        <div id={`${componentId}`} className={clsx(`glide col-12 px-0`)}>
          <div
            className={clsx(
              'glide__track',
              !isSlideArrowsVisible ? 'd-flex flex-column' : '',
              !isSlideArrowsVisible && contentBlockAlignment === 'center' ? 'align-items-center' : '',
              !isSlideArrowsVisible && contentBlockAlignment === 'left' ? 'align-items-start' : ''
            )}
            data-glide-el="track"
          >
            <ul
              role="tablist"
              className={clsx(
                `m-standard-tab-list`,
                'glide__slides',
                !isSlideArrowsVisible ? 'tabs-only' : '',
                !isSlideArrowsVisible && contentBlockAlignment === 'center' ? 'justify-content-center' : '',
                !isSlideArrowsVisible && contentBlockAlignment === 'left' ? 'justify-content-start' : ''
              )}
            >
              {tabDetails?.map((tab, key) => {
                const { tabName = '', tabIcon } = tab;
                return (
                  <li
                    key={key}
                    className={clsx('custom_click_track', `m-standard-tab-list-item`, { active: activeTab === key })}
                    {...{
                      custom_click_track_value: `${tabName}-tab`,
                    }}
                    role="tab"
                    aria-label={tabName}
                  >
                    <Button
                      ariaExpanded={tabName}
                      custom_click_track_value={`${tabName}-tab`}
                      trackingProperties={trackingProperties}
                      callback={() => handleTabClick(key)}
                      className={'tabIconText'}
                    >
                      <Icon iconClass={`${tabIcon}  iconContainer`} />
                      <RichText
                        text={tabName}
                        ref={tabNameTextRef}
                        componentId={componentId + '-TabContainerTabName'}
                        customClass={'t-font-m text-center mb-2'}
                      />
                    </Button>
                  </li>
                );
              })}
            </ul>
          </div>
          {!isEditMode && isSlideArrowsVisible && (
            <div className="center-align">
              <div className="carouselControlType1">
                <div className="glide__arrows" data-glide-el="controls">
                  <button
                    className={clsx('left-arrow', 'glide__arrow--left', arrowStyle)}
                    id={`${componentId}_left_arrow`}
                    data-glide-dir="<"
                    aria-label="left-arrow"
                    data-custom_click_track_value={`internal`}
                  >
                    <span className="icon-arrow-left" id={`${componentId}_left_arrow`} data-id="left"></span>
                  </button>
                </div>
                <div className="glide__arrows" data-glide-el="controls">
                  <button
                    className={clsx('right-arrow', 'glide__arrow--right', arrowStyle)}
                    id={`${componentId}_right_arrow`}
                    data-glide-dir=">"
                    aria-label="right-arrow"
                    data-custom_click_track_value={`internal`}
                  >
                    <span className="icon-arrow-right" id={`${componentId}_right_arrow`} data-id="right"></span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className={clsx(
            'tabpage-container',
            borderEnabled && 'tabpage-border',
            !isSlideArrowsVisible && 'tabpage-edge'
          )}
        >
          {childrenArray?.map((child, index) => (
            <div key={index} className={activeStates[index] ? 'd-block' : 'd-none'}>
              {child}
            </div>
          ))}
        </div>
      </div>
    </StyledTab>
  );
};
