/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { FC, useEffect, SyntheticEvent } from 'react';
import { CurrentLocationProps } from './CurrentLocation.types';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    google: any;
  }
}

const CurrentLocationGoogle: FC<CurrentLocationProps> = ({
  inputFieldHandler,
  currentLocationHiddenFieldHandler,
  apiKey,
  currentLocationLabel,
  render,
  iconTextBlockCustomClass,
}) => {
  const clickTrackingValue = 'Current Location Clicked';
  const clickHandler = (e: SyntheticEvent): void => {
    e.preventDefault();
    const locationHandler = (data: Record<string, string>): void => {
      const currentLocationInfo = data;
      // update the hidden input fields and destination input field
      currentLocationHiddenFieldHandler(currentLocationInfo);
      inputFieldHandler(currentLocationInfo['address']);
    };
    getCurrentLocation(locationHandler);
  };
  useEffect(() => {
    if (!window.google || window.google === undefined) {
      appendMapFile();
    }
  }, []);
  const appendMapFile = (): void => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;
    script.defer = true;

    document.head.appendChild(script);

    const script1 = document.createElement('script');
    script1.src = `https://unpkg.com/@googlemaps/markerwithlabel@1.0.3/dist/index.min.js`;
    script1.defer = true;

    document.head.appendChild(script1);
  };

  const getCurrentLocation = (callback: CallableFunction): void => {
    let currentLocation: Record<string, string>;

    // fetching the latitude and longitude
    navigator.geolocation.getCurrentPosition(
      (location: GeolocationPosition) => {
        const lat = location.coords.latitude;
        const lng = location.coords.longitude;

        if (window.google) {
          const geocoder = new window.google.maps.Geocoder();
          const latlng = { lat, lng };
          // using Goggle Maps API reverse geocoding service to convert latlng to location details
          geocoder.geocode(
            { location: latlng },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (results: any, status: any) => {
              if (status === 'OK') {
                if (results[0]) {
                  // only store the data required for hidden fields and input field
                  currentLocation = {
                    placeId: results[0].place_id,
                    address: results[0].formatted_address,
                    types: results[0].types.join(),
                  };
                  // using callback to pass the data
                  callback(currentLocation);
                } else {
                  window.alert('No location available.');
                }
              }
            }
          );
        }
      },
      (error: GeolocationPositionError) => {
        window.alert('Please enable geolocation in your browser settings.');
        return error;
      }
    );
  };
  return <>{render({ currentLocationLabel, clickTrackingValue, clickHandler, iconTextBlockCustomClass })}</>;
};

export default CurrentLocationGoogle;
