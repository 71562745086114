import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { RadioButton } from '../../atoms/RadioButton';
import { Heading } from '../../atoms/Heading';
import { headingType, size, tags } from '../../utils/enums/enums';
import { RadiobuttonControlsListProps, ControlsProps } from './RadiobuttonControlsList.types';
import { StyledRadiobuttonControlsList } from './RadiobuttonControlsList.styles';

const renderControl = (
  control: ControlsProps,
  selectedControls: ControlsProps[],
  handleControlToggle: (control: ControlsProps) => void,
  handleKeyDown: (event: React.KeyboardEvent, value: string, radiobuttonLabel: string) => void,
  name: string,
  index: number,
  custom_click_track_value: string,
  colLength: number,
  tabIndexForInput: number
) => {
  const controlId = `${name.replace(/ /g, '')}-${index}`;
  return (
    <RadioButton
      key={controlId}
      radiobuttonId={controlId}
      radiobuttonName={name}
      radiobuttonLabel={control?.count ? control?.label + ` (${control?.count})` : control?.label}
      checked={selectedControls.some((item: ControlsProps) => item?.label === control?.label)}
      onChange={() => handleControlToggle(control)}
      onKeyDown={handleKeyDown}
      setTabIndex={0}
      value={control?.code || control?.label || ''}
      disabled={control?.disabled}
      className={`radio-controls-list__radio col-${colLength || 6} pb-2`}
      labelClassName="t-font-s col-12 item-name checkbox-radio mb-0"
      customClickClass="radio-input custom_click_track"
      custom_click_track_value={custom_click_track_value}
      tabIndexForInput={tabIndexForInput}
    />
  );
};
export const RadiobuttonControlsList: React.FC<RadiobuttonControlsListProps> = ({
  title,
  subTitle = '',
  eyebrowText = '',
  controls,
  enableShowMoreToggle = false,
  showMoreCount = 0,
  onChange,
  defaultSelected = [],
  name,
  showMoreLabel = 'Show More',
  showLessLabel = 'Show Less',
  showSeparator = false,
  custom_click_track_value = '',
  colLength = 6,
  tabIndexForInput = 0,
}) => {
  const [selectedControls, setSelectedControls] = useState<ControlsProps[]>(defaultSelected);
  const [showMore, setShowMore] = useState(true);

  useEffect(() => {
    setSelectedControls(defaultSelected);
  }, [defaultSelected]);

  const handleControlToggle = (control: ControlsProps) => {
    let updatedSelection: ControlsProps[] = [];
    updatedSelection = [control];

    setSelectedControls(updatedSelection);
    onChange(updatedSelection);
  };

  const handleKeyDown = (event: React.KeyboardEvent, value: string, radiobuttonLabel: string) => {
    if (event.key === 'Enter') {
      handleControlToggle({ label: radiobuttonLabel, code: value });
    }
  };

  const renderedControls = !enableShowMoreToggle || !showMore ? controls : controls.slice(0, showMoreCount);
  const btnLabel = !showMore ? showLessLabel : showMoreLabel;

  return (
    <StyledRadiobuttonControlsList className={`col-12 radio-controls-list`}>
      <fieldset>
        {title && (
          <div className="my-4 mt-md-5 mb-md-4 mt-lg-5 mb-lg-4 mt-xl-5 mb-xl-4">
            <legend>
              <Heading
                variation={headingType.subtitle}
                titleText={title}
                fontSize={size.large}
                element={tags.h4}
                customClass={'radio-controls-list__title'}
              ></Heading>
            </legend>
          </div>
        )}
        {subTitle && (
          <Heading
            variation={headingType.subtitle}
            titleText={subTitle}
            fontSize={size.large}
            element={tags.h5}
            customClass={'pt-0 pb-3'}
          ></Heading>
        )}
        {eyebrowText && <p className="t-label-xs filter-sub-heading mb-0 pb-3 pt-0">{eyebrowText}</p>}
        <div className="row">
          {renderedControls.map((control, index) =>
            renderControl(
              control,
              selectedControls,
              handleControlToggle,
              handleKeyDown,
              name,
              index,
              custom_click_track_value,
              colLength,
              tabIndexForInput
            )
          )}
        </div>
        {enableShowMoreToggle && controls.length > showMoreCount && (
          <button className="show-more-btn t-font-s p-0" onClick={() => setShowMore(!showMore)}>
            {btnLabel}{' '}
            <span
              className={clsx('show-more-icon', { 'icon-dropdown-down': showMore, 'icon-dropdown-up': !showMore })}
            ></span>
          </button>
        )}
        {showSeparator && <hr className="filter-separator mt-4 mt-md-5 mt-lg-5 mt-xl-5" />}
      </fieldset>
    </StyledRadiobuttonControlsList>
  );
};
