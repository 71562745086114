/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/ban-ts-comment */
/* eslint-disable  @typescript-eslint/no-var-requires */
// @ts-ignore
import { useEffect, useState } from 'react';
import { useDCAPageModelStore, useClientEnvVarsStore } from '@marriott/mi-store-utils';
import { transformTargetModel } from '../lib/dynamicContentAssemblyUtils';
import { targetPayloadRequest } from '../lib/dynamicContentAssemblyUtils/targetSDKPayload';
import { dcaDynamicContent } from '@marriott/mi-merch-graphql';
import { useNextMiApolloClient } from '@marriott/mi-apollo-client-utils';
import createMappingModelJSON from '../lib/dynamicContentAssemblyUtils/responseMapping';
import operationSignatures from '../lib/dynamicContentAssemblyUtils/_constants/operationSignatures.json';
import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';

const transformJSON = require('json-map-transform');

declare global {
  interface Window {
    sessionData: any;
    mvpOffers?: {
      specialMessage?: string;
      rpcCode?: string;
      offerCode?: string;
    };
    _satellite: Record<string, any>;
  }
}

export const useDCADynamicModel = (mboxParam?: string) => {
  if (!mboxParam) {
    //If mbox parameter is not present isTargetContentLoading is false and component behavior is not effected by target.
    return { targetData: {} };
  }
  const [compKey, setCompkey] = useState<string | undefined>('');
  const { pageModel, updateComponent } = useDCAPageModelStore((state: unknown) => state) as any;

  function findKeysWithMatchingValues(obj: any, target: string) {
    let matchingKey = '';
    function search(obj: any, currentKey: string | null = null) {
      for (const key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
          // If it's a nested object, search recursively
          search(obj[key], key);
        } else if (obj[key] === target) {
          // If the value matches the target, add the key to the list
          if (currentKey) matchingKey = currentKey;
        }
      }
    }
    search(obj);
    return matchingKey;
  }

  function findKeysWithMatchingValuesAndObject(obj: any, target: string) {
    let paths = '';
    function search(obj: any, path: string[] = []) {
      for (const key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
          search(obj[key], path.concat(key));
        } else if (obj[key] === target) {
          paths = path.join('.');
        }
      }
    }
    search(obj);
    return paths;
  }

  function getDataFromPath(obj: any, path: string) {
    const allKeys = path?.split('.');
    let requiredObj: any = obj;
    allKeys?.forEach((key: string) => {
      requiredObj = requiredObj?.[key];
    });
    return requiredObj;
  }

  useEffect(() => {
    const compKey = findKeysWithMatchingValues(pageModel, mboxParam);
    setCompkey(compKey);
  }, []);

  useEffect(() => {
    const fetchAndUpdateTargetData = async () => {
      const options = targetPayloadRequest({
        locale:
          window?.sessionData?.locale?.replace('-', '_') ||
          window?._satellite?.['getVar']('brwsrLang') ||
          window?.dataLayer?.['brwsrLang'] ||
          'en_US',
        mboxName: mboxParam,
        propertyToken:
          process.env['TARGET_PROPERTY_TOKEN'] ||
          useClientEnvVarsStore.getState().envVarsObject['TARGET_PROPERTY_TOKEN'],
      });
      if (mboxParam && compKey && !AuthoringUtils.isInEditor() && !AuthoringUtils.isPreviewMode()) {
        try {
          const MiApolloClient = useNextMiApolloClient(
            operationSignatures,
            useClientEnvVarsStore.getState().envVarsObject['DEPLOYED_ENV_TYPE']
          );
          const { data, errors } = await MiApolloClient.query({
            query: dcaDynamicContent,
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            variables: {
              input: options,
            },
            context: {
              headers: {},
            },
          });
          const resp = data.dynamicContentByChannelAndMboxes.mboxes[0];
          let transformerData;
          if ((errors && errors.length) || !resp) {
            throw new Error('DCA call error or mbox parameter is empty');
          }
          if (resp) {
            const mappingModelJSON = createMappingModelJSON();
            // Mapping should happen at AEM level, need to check with AEM team on why there is a gap
            const mappedResponse = transformJSON(resp, mappingModelJSON);
            const requiredPath = findKeysWithMatchingValuesAndObject(pageModel, mboxParam);
            const compModel = getDataFromPath(pageModel, requiredPath);
            transformerData = transformTargetModel(compModel, mappedResponse);
          }
          if (transformerData) {
            // Create a new object with isTargetContentLoaded property
            const updatedTransformerData = { ...transformerData, isTargetContentLoaded: true };
            updateComponent(compKey, updatedTransformerData, requiredPath);
          }
        } catch (error) {
          console.log(error);
          // Create a new object with isTargetContentLoaded property
          const requiredPath = findKeysWithMatchingValuesAndObject(pageModel, mboxParam);
          const compModel = getDataFromPath(pageModel, requiredPath);
          const transformerData = {
            ...compModel,
            isTargetContentLoaded: true,
            isTargetContentError: true,
          };
          if (transformerData) {
            // Create a new object with isTargetContentLoaded property
            const updatedTransformerData = { ...transformerData, isTargetContentLoaded: true };
            updateComponent(compKey, updatedTransformerData, requiredPath);
          }
        }
      }
    };
    fetchAndUpdateTargetData();
  }, [mboxParam, updateComponent, compKey]);

  const requiredPath = findKeysWithMatchingValuesAndObject(pageModel, mboxParam);
  const compModel = getDataFromPath(pageModel, requiredPath);
  return {
    targetData: compModel || {},
  };
};
