/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';

import { EditableComponent } from '@adobe/aem-react-editable-components';
import { AvailabilityCalendarProps, AlertMessageBodyProps, AEMAlertBodyProps } from './AvailabilityCalendar.types';
import { ADFPageContextProvider, usePageContext } from '../../context';
import { CalendarWrapper } from '../../molecules/CalendarWrapper';
import { StyledAvailabilityCalendarDiv } from './AvailabilityCalendar.styles';
import { TabComponent, Text, Types } from '@marriott/mi-ui-library';
import { eventUtil } from '@marriott/mi-ui-library-shop';
import { phoenixShopADFSearchProductsByProperty } from './queries';
import { useCheckBreakpoint } from '../../hooks';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';
import { Messages } from '@marriott/mi-ui-library';
import clsx from 'clsx';
import {
  AVG_TAB,
  DATE_FORMAT_YEAR_MONTH_DAY,
  NUMBER_OF_DAYS_FOR_PREVIOUS_MONTH,
  TOTAL_NUMBER_OF_DAYS_FOR_DATA,
  TOTAL_TAB,
  TOO_MANY_ROOMS_FOR_FLEXIBLE_ERROR,
  GROUP_CODE_FEW_CHARS_ERROR,
  CORP_CODE_CHARS_LENGTH_ERROR,
  GROUP_CLUSTER_CODE,
  CORP_CLUSTER_CODE,
  FUTURE_DATE_ERRROR,
  CORP_CODE_CHARS_INVALID_ERROR,
} from '../../constants/Calendar.constant';
import { updateDataLayerOnInteractions, updateDataLayerObj } from '../../modules/utils';
import { validateAvailabilityRequest } from '../../lib/organisms/SearchForm/utils/Helpers';
import { SkeletonLoader } from '../../molecules/SkeletonLoader';
import { REWARDS_REDEMPTION_CLUSTER_CODES } from '../../constants/ADFCalendar.constant';
import { LATIN_NAME_REGEX } from '../../constants/lib/constants';

declare global {
  interface Window {
    // Setting type to any to stay in sync with book team sessionData type declaration
    sessionData: any;
  }
}

export const AvailabilityCalendar: React.FC<AvailabilityCalendarProps> = props => {
  const { model } = props;
  const [showCalendar, setShowCalendar] = useState(false);
  const jsonContent = { ...model };
  const [dataLoading, setDataLoading] = useState(false);
  const isDesktop = useCheckBreakpoint('viewportL');
  const {
    sessionData,
    currentLocale,
    resolvedUrl,
    headersData,
    query,
    datalayerParams,
    datalayerProperties,
    interceptorResponse,
    preHeader,
    NEXT_PUBLIC_PRE_PROCESSING_RELATIVE_ADF_URL,
  } = usePageContext();
  const sessionID = sessionData?.sessionToken
    ? sessionData?.sessionToken
    : sessionData?.data?.sessionToken
    ? sessionData?.data?.sessionToken
    : sessionData?.cacheData?.key
    ? sessionData?.cacheData?.key
    : sessionData?.cacheData?.data?.sessionToken;

  const { AriesSearch, AriesCommon } = sessionData.data ?? sessionData.cacheData.data;
  const { availabilityRequestVO, propertyId, selectedCurrency } = AriesSearch.searchCriteria;

  const {
    checkInDate,
    clusterCode,
    corporateCode,
    numRooms,
    numGuestsPerRoom,
    rewardsRedemption,
    lengthOfStay,
    flexibleDate,
    groupCode,
  } = availabilityRequestVO;
  const [adfData, setAdfData] = useState<any>();
  const [formData, setFormData] = useState<any>();
  const alertBody: AEMAlertBodyProps[] = jsonContent?.['adfAlertBody'] as any;

  const [selectedDays, setSelectedDays] = useState(lengthOfStay > 0 && lengthOfStay <= 9 ? lengthOfStay : 1);
  const [selectedTab, setSelectedTab] = useState(lengthOfStay > 1 ? AVG_TAB : TOTAL_TAB);
  const [isPointsSelected, setIsPointsSelected] = useState(rewardsRedemption);
  const [selectedCluster, setSelectedCluster] = useState(clusterCode);
  const [selectedCorporateCode, setSelectedCorporateCode] = useState(
    clusterCode?.toLowerCase() === 'group' ? groupCode : corporateCode
  );
  const [selectedNumOfGuests, setSelectedNumOfGuests] = useState(numGuestsPerRoom);
  const [selectedRoomCount, setSelectedRoomCount] = useState(numRooms);
  const inventoryDates = AriesCommon?.singleDateLimit;
  const [dateRange, setDateRange] = useState<Array<string>>([]);
  const [defaultMonthDate, setdefaultMonthDate] = useState(
    moment(checkInDate).utc().format(DATE_FORMAT_YEAR_MONTH_DAY) ?? moment().format(DATE_FORMAT_YEAR_MONTH_DAY)
  );
  const [isRedemption, setIsRedemption] = useState(false);

  const { size, tags } = Types;
  const [alertMessage, setAlertMessage] = useState<AlertMessageBodyProps>({});
  const [updateAdfSearchCounter, setUpdateAdfSearchCounter] = useState<number>(0);
  const isViewportM = useCheckBreakpoint('viewportM');

  let fromCurrency: string = '';
  if (adfData && adfData?.searchProductsByProperty) {
    fromCurrency = adfData?.searchProductsByProperty?.edges[0]?.node?.rates?.rateAmounts[0]?.amount?.origin?.currency;
  }
  const currentCurrency =
    (selectedCurrency && selectedCurrency.toLowerCase() !== 'default' ? selectedCurrency : fromCurrency) ?? 'USD';

  const queryCommonContext = {
    /**
     * define common params for all the query
     */
    context: {
      headers: {
        'x-request-id': `${Date.now()}`,
        'accept-language': currentLocale?.replace('_', '-'),
      },
    },
  };
  let tabList = [
    {
      tabTitle: model?.['avgNightlyCostTabText'],
      tabSubtitle: model?.['avgNightlyCostPlaceholderText'] + ` (${currentCurrency})`,
      tabValue: AVG_TAB,
      isActive: false,
    },
    {
      tabTitle: model?.['totalRoomCostTabText'],
      tabSubtitle: model?.['totalRoomCostPlaceholderText'] + ` (${currentCurrency})`,
      tabValue: TOTAL_TAB,
      isActive: false,
    },
  ];

  if (selectedDays < 2 || model?.['disableAvgNightlyCostSection']) {
    tabList = tabList.filter(item => item.tabValue !== AVG_TAB);
  }
  if (model?.['disableTotalRoomCostSection']) {
    tabList = tabList.filter(item => item.tabValue !== TOTAL_TAB);
  }

  useEffect(() => {
    displaySessionBasedAlerts(sessionData);
  }, []);

  useEffect(() => {
    if (selectedDays < 2) {
      setSelectedTab(TOTAL_TAB);
    } else {
      setSelectedTab(AVG_TAB);
    }
    setShowCalendar(true);
    // Get the query parameters from the URL
    const searchParams = new URLSearchParams(window.location.search);
    const costTabValue = searchParams.get('costTab');
    if (costTabValue) {
      if (costTabValue === 'average' && selectedDays > 1) {
        setSelectedTab(AVG_TAB);
      } else {
        setSelectedTab(TOTAL_TAB);
      }
    } else {
      if (lengthOfStay > 1) {
        setSelectedTab(AVG_TAB);
      } else {
        setSelectedTab(TOTAL_TAB);
      }
    }
  }, []);

  const SearchCounterValue = () => {
    /**
     * To get and set Counter value in sessionStorage
     * and later updating hash in the url
     * */
    if (typeof window !== 'undefined') {
      if (sessionStorage.getItem('updateAdfSearchCounter')) {
        const maxValue = parseInt(sessionStorage.getItem('updateAdfSearchCounter') as string);
        /**
         * set the previous counter value with +1 on update search
         */
        sessionStorage.setItem('updateAdfSearchCounter', (maxValue + 1).toString());
        setUpdateAdfSearchCounter(maxValue + 1);
      } else {
        sessionStorage.setItem('updateAdfSearchCounter', `${updateAdfSearchCounter}`);
      }
      const searchCounterValue = sessionStorage.getItem('updateAdfSearchCounter');
      // Append the counter value to the window
      if (window && window.dataLayer) {
        window.dataLayer['updates_search_call_counter'] = searchCounterValue;
      }
      // Update the window.location.hash with the update search counter
      window.history.replaceState({}, headersData?.referer, `#/${searchCounterValue}/`);
    }
  };

  useEffect(() => {
    const lastMonthDates = moment(checkInDate)
      ?.utc()
      .startOf('M')
      .subtract(getUTCNoOfPrevMonthDays(moment(checkInDate)?.utc().startOf('M')), 'days'); // get date 6 days older to current month date
    const isPastDate = lastMonthDates?.diff(moment()) < 0 ? true : false; // check if above date is older than today
    const startDate = isPastDate ? moment() : lastMonthDates; // if 'lastMonthDates' is past date then fromDate is today , else fromDate is 'lastMonthDates'

    const endDate = startDate
      .clone()
      .add(
        isPastDate && lastMonthDates.format('MMMM') === moment().format('MMMM')
          ? Number(
              getUTCNoOfPrevMonthDays(moment(checkInDate)?.utc().startOf('M')) >= 5 ? TOTAL_NUMBER_OF_DAYS_FOR_DATA : 34
            ) + Number(lastMonthDates?.diff(moment(), 'days'))
          : getUTCNoOfPrevMonthDays(moment(checkInDate)?.utc().startOf('M')) >= 5
          ? TOTAL_NUMBER_OF_DAYS_FOR_DATA
          : 34,
        'days'
      )
      .format(DATE_FORMAT_YEAR_MONTH_DAY);
    const startDateFormatted = startDate.format(DATE_FORMAT_YEAR_MONTH_DAY);
    setdefaultMonthDate(
      moment(checkInDate).utc().diff(moment().startOf('M')) < 0
        ? moment().format(DATE_FORMAT_YEAR_MONTH_DAY)
        : moment(checkInDate).utc().format(DATE_FORMAT_YEAR_MONTH_DAY)
    ); // change default month to show on ADF calendar based on start date
    const requestObj = {
      search: {
        propertyId: propertyId,
        options: {
          startDate: startDateFormatted,
          quantity: Number(numRooms),
          rateRequestTypes: getRateRequestType(
            rewardsRedemption,
            clusterCode,
            clusterCode?.toLowerCase() === 'group' ? groupCode : corporateCode
          ),
          endDate:
            moment(endDate).diff(moment(inventoryDates), 'd') > 0
              ? moment(inventoryDates).subtract(1, 'd').format(DATE_FORMAT_YEAR_MONTH_DAY)
              : endDate,
          numberInParty: numGuestsPerRoom,
          productStatusType: 'AVAILABLE',
          numberOfDays: Math.trunc(lengthOfStay) > 9 ? 1 : Math.trunc(lengthOfStay), //In case of flexible search maximum allowed nights is 9 so  length of stay is reset to 1 incase of morethan 9 nights in flexible and  need this variable to calculate total price
          flexibleDates: true,
        },
      },
    };
    setDateRange([startDateFormatted, endDate]);
    setDataLoading(true);

    /**
     * To get and set Counter value in sessionStorage
     * and later updating hash in the url on the page-reload
     * */
    SearchCounterValue();
    getADFSearchData({
      variables: requestObj,
      ...queryCommonContext,
    });
    eventUtil.dispatch('loadSearchForm', true);
  }, []);

  /**
   * Append searchType attribute to datalayer window
   * */
  if (typeof window !== 'undefined') {
    if (window && window.dataLayer) {
      const searchTypeValue = AriesSearch?.searchCriteria?.searchType;
      window.dataLayer['searchType'] = searchTypeValue;
    }
  }

  /**
   * Function to manipulate the aem  content to be display no of nights based on user selection
   */
  const replaceDays = () => {
    for (let i = 0; i < tabList?.length; i++) {
      tabList[i].tabSubtitle = tabList[i]?.tabSubtitle
        ?.replace(/[0-9]/g, `${selectedDays}`)
        ?.replace('{X}', `${selectedDays}`);

      if (selectedDays < 2) {
        tabList[i].tabSubtitle = tabList[i]?.tabSubtitle?.replace('nights', 'night');
      }
    }
  };
  replaceDays();

  //below code is to call UXL on page load once inventoryDates are available

  // function to call UXL for ADF data/rates
  const [getADFSearchData] = useLazyQuery(phoenixShopADFSearchProductsByProperty, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onCompleted: data => {
      loadAfterSearchData(data);
    },
    onError: () => {
      setDataLoading(false);
    },
  });

  // function to call UXL once we get response from UXL
  const loadAfterSearchData = (data: any) => {
    setDataLoading(false);
    setAdfData(data);
  };

  //function to determine the request type to send to UXL based on search form data / session data
  const getRateRequestType = (usePoints: boolean, clusterCode: string, corporateCode: string) => {
    setIsRedemption(false);
    let rateRequestTypes;
    if (usePoints) {
      rateRequestTypes = [
        {
          value: 'REDEMPTION',
          type: 'REDEMPTION',
        },
      ];
    } else {
      rateRequestTypes = [
        {
          value: '',
          type: 'STANDARD',
        },
      ];
      if (clusterCode.toLowerCase() === 'aaa') {
        rateRequestTypes = [
          {
            value: '',
            type: 'AAA',
          },
        ];
      } else if (clusterCode.toLowerCase() === 'gov') {
        rateRequestTypes = [
          {
            value: '',
            type: 'GOV',
          },
        ];
      } else if (clusterCode.toLowerCase() === 's9r') {
        rateRequestTypes = [
          {
            value: '',
            type: 'SENIOR_S9R',
          },
        ];
      } else if (clusterCode.toLowerCase() === 'corp') {
        if (REWARDS_REDEMPTION_CLUSTER_CODES.includes(corporateCode?.toUpperCase())) {
          setIsRedemption(true);
          rateRequestTypes = [
            {
              value: 'REDEMPTION',
              type: 'REDEMPTION',
            },
          ];
        } else {
          rateRequestTypes = [
            {
              value: corporateCode,
              type: 'CLUSTER',
            },
          ];
        }
      } else if (clusterCode.toLowerCase() === GROUP_CLUSTER_CODE) {
        /*
        Logic to ignore the group codes on ADF page, remove this once group codes are enabled for ADF
      */
        rateRequestTypes = [
          {
            value: '',
            type: 'STANDARD',
          },
        ];
      }
    }
    return rateRequestTypes;
  };

  // callback function that will be used by the replace function in getFormattedErrorKey
  // used to remove full stops and convert to camel case
  function replacer(match: string): string {
    return match?.[1]?.toUpperCase();
  }

  // function that accepts dot separated string and returns camel cased key
  function getFormattedErrorKey(input: string) {
    return input.toLowerCase().replace(/\../g, replacer);
  }

  function returnApplicableAlert(matchKey: string) {
    const returnedAlert: AlertMessageBodyProps = {};
    const shortlistedAlert = alertBody?.filter(
      alert => alert?.adfAlertMsgTypeName.toLowerCase() === matchKey.toLowerCase()
    )?.[0];
    returnedAlert['alertMessage'] = shortlistedAlert?.adfAlertCodeListItems[0]?.['adfAlertMessage'];
    returnedAlert['alertHeading'] = shortlistedAlert?.adfAlertCodeListItems[0]?.['adfAlertHeading'];
    returnedAlert['alertCode'] = shortlistedAlert?.adfAlertCodeListItems[0]?.['adfAlertCode'];
    returnedAlert['alertType'] = shortlistedAlert?.adfAlertCodeListItems[0]?.['adfAlertType'];
    if (matchKey === FUTURE_DATE_ERRROR) {
      const date = inventoryDates ?? '';
      returnedAlert['alertMessage'] += ' ' + date.split('-').reverse().join('-');
    }
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
    return returnedAlert;
  }
  useEffect(() => {
    displayErrorMessageOnLoad();
  }, []);

  const displayErrorMessageOnLoad = () => {
    const queries = new URLSearchParams(window.location.href);
    const roomCount = queries?.get('roomCount') || queries?.get('numberOfRooms') || '';
    const corpCode = corporateCode || queries?.get('corporateCode') || '';
    const clusCode = clusterCode || queries?.get('clusterCode') || '';
    displayApplicableAlert(flexibleDate, roomCount, corpCode, clusCode, '', false, {});
  };
  async function displayApplicableAlert(
    flexible: string | boolean,
    roomsCount: string,
    corporateCode: string,
    clusterCode: string,
    newSearchParams: string,
    fromUpdateSearch: boolean,
    obj: any
  ) {
    setAlertMessage({});
    let applicableAlert: AlertMessageBodyProps = {};
    let errorKey = '';
    // error case when flexible dates are true and dates are beyond inventory dates
    // Error key: checkin.date.future.not.allowed.invalid
    if (moment(checkInDate).diff(moment(inventoryDates), 'd') > 0) {
      errorKey = FUTURE_DATE_ERRROR;
    }
    // error case when flexible dates are true and rooms are greater than 3
    // Error key: flexible.date.room.count.invalid
    else if ((flexible === 'true' || flexibleDate === true) && parseInt(roomsCount, 10) > 3) {
      errorKey = TOO_MANY_ROOMS_FOR_FLEXIBLE_ERROR;
    }
    // error case when group code has insufficient characters
    // Error key: group.code.fewer.than.7.char.error.text
    else if (
      corporateCode?.length < 7 &&
      corporateCode?.length >= 1 &&
      clusterCode.toLowerCase() === GROUP_CLUSTER_CODE
    ) {
      errorKey = GROUP_CODE_FEW_CHARS_ERROR;
    } else if (
      corporateCode?.length >= 1 &&
      clusterCode?.toLowerCase() === CORP_CLUSTER_CODE &&
      LATIN_NAME_REGEX.test(corporateCode) !== true
    ) {
      errorKey = CORP_CODE_CHARS_INVALID_ERROR;
    } else if (
      (corporateCode?.length > 9 || corporateCode?.length < 2) &&
      corporateCode?.length >= 1 &&
      clusterCode?.toLowerCase() === CORP_CLUSTER_CODE
    ) {
      errorKey = CORP_CODE_CHARS_LENGTH_ERROR;
    }
    // if not the above two errors, there could be an error in the session doc
    else {
      errorKey = '';
    }
    if (errorKey && errorKey !== undefined && errorKey !== '') {
      applicableAlert = returnApplicableAlert(errorKey);
      if (applicableAlert && Object.keys(applicableAlert).length > 0) {
        setAlertMessage(applicableAlert);
      } else {
        if (fromUpdateSearch) {
          const response = await validateAvailabilityRequest(
            flexible?.toString() === 'true',
            NEXT_PUBLIC_PRE_PROCESSING_RELATIVE_ADF_URL,
            propertyId,
            currentCurrency,
            false,
            interceptorResponse,
            preHeader,
            true,
            newSearchParams,
            false
          );
          if (response) {
            updateDataLayerOnInteractions(
              resolvedUrl,
              headersData,
              query,
              datalayerParams,
              currentLocale,
              sessionID,
              propertyId
            );
            displaySessionBasedAlerts(response?.data?.sessionData);
            const redirectSuccessUri = response?.headers?.['location'] || response?.headers?.['Location'];
            const isNotNavigatingToAdf =
              redirectSuccessUri && redirectSuccessUri?.indexOf('/search/availabilityCalendar') === -1;
            if (response.status === 200 && !isNotNavigatingToAdf) {
              //calling uxl only incase of staying in the page without redirection
              setDataLoading(true);
              getADFSearchData({
                variables: obj,
                ...queryCommonContext,
              });
            }
          }
        }
      }
    } else {
      if (fromUpdateSearch) {
        const response = await validateAvailabilityRequest(
          flexible?.toString() === 'true',
          NEXT_PUBLIC_PRE_PROCESSING_RELATIVE_ADF_URL,
          propertyId,
          currentCurrency,
          false,
          interceptorResponse,
          preHeader,
          true,
          newSearchParams,
          false
        );
        if (response) {
          updateDataLayerOnInteractions(
            resolvedUrl,
            headersData,
            query,
            datalayerParams,
            currentLocale,
            sessionID,
            propertyId
          );
          const redirectSuccessUri = response?.headers?.['location'] || response?.headers?.['Location'];
          const isNotNavigatingToAdf =
            redirectSuccessUri && redirectSuccessUri?.indexOf('/search/availabilityCalendar') === -1;
          displaySessionBasedAlerts(response?.data?.sessionData);
          if (response.status === 200 && !isNotNavigatingToAdf) {
            //calling uxl only incase of staying in the page without redirection
            setDataLoading(true);
            getADFSearchData({
              variables: obj,
              ...queryCommonContext,
            });
          }
        }
      }
    }
  }

  function displaySessionBasedAlerts(sessionDataValue: typeof sessionData) {
    const { AriesReservation } = (sessionDataValue?.data ?? sessionDataValue?.cacheData?.data) || {};
    let errorKey = '';
    let applicableAlert: AlertMessageBodyProps = {};
    if (AriesReservation?.errorMessages?.availabilitySearchFormErrorMessages) {
      const sessionErrorMessages =
        AriesReservation?.errorMessages?.availabilitySearchFormErrorMessages?.errorMessageKeys;
      const sessionErrorKey = sessionErrorMessages?.[0];
      const splitErrorMessages = ('|' + sessionErrorKey)?.split('|');
      errorKey = getFormattedErrorKey(splitErrorMessages[splitErrorMessages.length - 1] ?? '');
    }
    if (errorKey !== '' || errorKey !== undefined) {
      applicableAlert = returnApplicableAlert(errorKey);
      if (applicableAlert && applicableAlert.alertCode) {
        setAlertMessage(applicableAlert);
      }
    }
  }

  /**
   * Function to count the no of days from previous month displayed in current month view
   * @param {moment.MomentInput}  day selected month passed to fetch the days
   */

  const getUTCNoOfPrevMonthDays = (day: moment.MomentInput) => {
    let noOfPrevMonthDays =
      Number(moment(moment(day).utc().format(DATE_FORMAT_YEAR_MONTH_DAY)).day()) || NUMBER_OF_DAYS_FOR_PREVIOUS_MONTH;
    if (noOfPrevMonthDays === 6) {
      if (moment(day).startOf('M')?.format('dddd').toString()?.toLowerCase() === 'sunday') {
        noOfPrevMonthDays = Number(jsonContent['firstDayOfWeek']) === 1 ? 7 : 0;
      } else {
        noOfPrevMonthDays = 6;
      }
    }
    return Number(jsonContent['firstDayOfWeek']) > 0
      ? noOfPrevMonthDays - Number(jsonContent['firstDayOfWeek'])
      : noOfPrevMonthDays;
  };

  //below function calls UXL for ADF data/rates when user changes search form data from ADF page
  useEffect(() => {
    /**
     * Event to capture the user values from search form
     */
    eventUtil.on('availabilityCalendarSearchFormData', data => {
      setFormData(data);
      const { formValues, params, flexible } = data;
      const { dates, usePoints, roomsAndGuests, specialRates } = formValues;
      const { fromDate, toDate } = dates;

      const { adultsCount, childrenCount, roomsCount } = roomsAndGuests;
      const { clusterCode, corporateCode } = specialRates;
      const rateRequestTypes = getRateRequestType(usePoints.value, clusterCode, corporateCode);
      const searchParams = new URLSearchParams(params);
      searchParams.delete('isRateCalendar');
      searchParams.delete('isSearch');
      searchParams.delete('currency');
      if (searchParams.get('clusterCode')?.toLocaleLowerCase() === 'group') {
        searchParams.set('groupCode', searchParams.get('corporateCode') || '');
        searchParams.delete('corporateCode');
      }

      let newSearchParams = searchParams.toString();
      //to check if we are getting data from searchProducts and comparing with Dates
      //We will only get those dates from searchProducts for which dates are available for checkin
      if (
        adfData?.searchProductsByProperty?.edges?.findIndex((edge: any) => {
          return edge?.node?.basicInformation?.startDate === fromDate?.format(DATE_FORMAT_YEAR_MONTH_DAY);
        }) === -1
      ) {
        newSearchParams = searchParams.toString() + 'unsuccessfulSell=true';
        window.history.replaceState({}, headersData?.referer, decodeURIComponent(`?${newSearchParams}`));
      }

      setSelectedCluster(clusterCode);
      setSelectedCorporateCode(corporateCode);
      setSelectedNumOfGuests(adultsCount + childrenCount);
      setSelectedRoomCount(roomsCount);
      if (toDate && toDate?.diff) {
        setSelectedDays(toDate?.diff(fromDate, 'days'));
      }

      if (toDate && toDate?.diff && toDate?.diff(fromDate, 'days') === 1) {
        setSelectedTab(TOTAL_TAB);
      } else {
        setSelectedTab(AVG_TAB);
      }
      setIsPointsSelected(usePoints?.value);
      const lastMonthDates = moment(fromDate)?.startOf('M').subtract(getNoOfPrevMonthDays(fromDate), 'days'); // get date 6 days older to current month date
      const isPastDate = lastMonthDates?.diff(moment()) < 0 ? true : false; // check if above date is older than today
      const startDate = isPastDate ? moment() : lastMonthDates; // if 'lastMonthDates' is past date then fromDate is today , else fromDate is 'lastMonthDates'
      const endDate = startDate
        ?.clone()
        .add(
          isPastDate && lastMonthDates.format('MMMM') === moment().format('MMMM')
            ? Number(getNoOfPrevMonthDays(fromDate) >= 5 ? TOTAL_NUMBER_OF_DAYS_FOR_DATA : 34) +
                Number(lastMonthDates?.diff(moment(), 'days'))
            : getNoOfPrevMonthDays(fromDate) >= 5
            ? TOTAL_NUMBER_OF_DAYS_FOR_DATA
            : 34,
          'days'
        )
        .format(DATE_FORMAT_YEAR_MONTH_DAY);
      const startDateFormatted = startDate?.format(DATE_FORMAT_YEAR_MONTH_DAY);
      if (fromDate && fromDate?.format && fromDate?.format(DATE_FORMAT_YEAR_MONTH_DAY)) {
        setdefaultMonthDate(fromDate?.format(DATE_FORMAT_YEAR_MONTH_DAY));
      }
      // change default month to show on ADF calendar based on start date

      const obj = {
        search: {
          propertyId: propertyId,
          options: {
            startDate: startDateFormatted,
            quantity: Number(roomsCount),
            rateRequestTypes: rateRequestTypes,
            endDate:
              moment(endDate).diff(moment(inventoryDates), 'd') > 0
                ? moment(inventoryDates).subtract(1, 'd').format(DATE_FORMAT_YEAR_MONTH_DAY)
                : endDate,
            numberInParty: adultsCount + childrenCount,
            productStatusType: 'AVAILABLE',
            numberOfDays:
              toDate && toDate?.diff
                ? Math.trunc(toDate?.diff(fromDate, 'days')) > 9
                  ? 1
                  : Math.trunc(toDate?.diff(fromDate, 'days'))
                : Math.trunc(lengthOfStay), //In case of flexible search maximum allowed nights is 9 so  length of stay is reset to 1 incase of morethan 9 nights in flexible and  need this variable to calculate total price
            flexibleDates: true,
          },
        },
      };
      displayApplicableAlert(flexible, roomsCount, corporateCode, clusterCode, newSearchParams, true, obj);

      setDateRange([startDateFormatted, endDate]);
      // eventUtil.on('callSearchProductsByProperty', () => {

      // });

      /**
       * To get and set Counter value in sessionStorage
       * and later updating hash in the url on update search
       * */
      SearchCounterValue();
    });
    return (): void => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      eventUtil.remove('availabilityCalendarSearchFormData', () => {});
    };
  }, []);

  /**
   * Function to count the no of days from previous month displayed in current month view
   * @param {moment.MomentInput}  day selected month passed to fetch the days
   */

  const getNoOfPrevMonthDays = (day: moment.MomentInput) => {
    let noOfPrevMonthDays = Number(moment(day).startOf('M').day()) || NUMBER_OF_DAYS_FOR_PREVIOUS_MONTH;

    if (noOfPrevMonthDays === 6) {
      if (moment(day).startOf('M')?.format('dddd').toString()?.toLowerCase() === 'sunday') {
        noOfPrevMonthDays = Number(jsonContent['firstDayOfWeek']) === 1 ? 7 : 0;
      } else {
        noOfPrevMonthDays = 6;
      }
    }
    return Number(jsonContent['firstDayOfWeek']) > 0
      ? noOfPrevMonthDays - Number(jsonContent['firstDayOfWeek'])
      : noOfPrevMonthDays;
  };
  //function gets called when user changes months manually
  const getRatesOnMonthChange = (month: Date) => {
    const lastMonthDates = moment(month)?.startOf('M').subtract(getNoOfPrevMonthDays(month), 'days'); // get date 6 days older to current month date
    const isPastDate = lastMonthDates?.diff(moment()) < 0 ? true : false; // check if above date is older than today
    const fromDate = isPastDate ? moment() : lastMonthDates; // if 'lastMonthDates' is past date then fromDate is today , else fromDate is 'lastMonthDates'
    const endDate = fromDate
      ?.clone()
      .add(
        isPastDate && lastMonthDates.format('MMMM') === moment().format('MMMM')
          ? Number(getNoOfPrevMonthDays(month) >= 5 ? TOTAL_NUMBER_OF_DAYS_FOR_DATA : 34) +
              Number(lastMonthDates?.diff(moment(), 'days'))
          : getNoOfPrevMonthDays(month) >= 5
          ? TOTAL_NUMBER_OF_DAYS_FOR_DATA
          : 34,
        'days'
      )
      .format(DATE_FORMAT_YEAR_MONTH_DAY);
    const formDataFromatted = fromDate.format(DATE_FORMAT_YEAR_MONTH_DAY);
    setdefaultMonthDate(moment(month).format(DATE_FORMAT_YEAR_MONTH_DAY));
    const obj = {
      search: {
        propertyId: propertyId,
        options: {
          startDate: formDataFromatted,
          quantity: Number(selectedRoomCount),
          rateRequestTypes: getRateRequestType(isPointsSelected, selectedCluster, selectedCorporateCode),
          endDate:
            moment(endDate).diff(moment(inventoryDates), 'd') > 0
              ? moment(inventoryDates).subtract(1, 'd').format(DATE_FORMAT_YEAR_MONTH_DAY)
              : endDate,
          numberInParty: selectedNumOfGuests,
          productStatusType: 'AVAILABLE',
          numberOfDays: Math.trunc(selectedDays) > 9 ? 1 : Math.trunc(selectedDays), //In case of flexible search maximum allowed nights is 9  so length of stay is reset to 1 incase of morethan 9 nights in flexible and  need this variable to calculate total price
          flexibleDates: true,
        },
      },
    };
    setDateRange([formDataFromatted, endDate]);

    setDataLoading(true);

    getADFSearchData({
      variables: obj,
      ...queryCommonContext,
    });
  };
  useMemo(() => {
    replaceDays();
  }, [selectedDays, selectedTab]);
  /**
   * Function to render the content to be display in mini property card
   * @param {number}  item  which contains the selected tab data
   */
  const handleTabs = (item: string) => {
    setSelectedTab(item);
  };

  /**
   * Datalayer call on interactions and component render
   */
  useEffect(() => {
    const loadDatalayer = async (): Promise<void> => {
      updateDataLayerObj(window?.dataLayer ?? {}, datalayerProperties);
    };
    loadDatalayer();
    // Get the current URL
    const currentUrl = window.location.href;
    // Parse the URL to extract the query parameters
    const url = new URL(currentUrl);
    const params = new URLSearchParams(url.search);
    const currentCostTab = selectedTab;
    // Update the costTab parameter in the URL
    if (currentCostTab === AVG_TAB) {
      params.set('costTab', 'average');
    } else {
      params.set('costTab', 'total');
    }
    // Reconstruct the URL with the updated query parameters
    url.search = params.toString();
    // Replace the current URL with the updated URL
    window.history.replaceState({}, '', url.toString());
  }, [formData, selectedTab]);

  const calendarComp = () => {
    return (
      <CalendarWrapper
        selectedTab={selectedTab}
        adfData={adfData}
        isDataLoading={dataLoading}
        formData={formData ? formData.formValues : {}}
        jsonContent={jsonContent}
        onMonthChange={getRatesOnMonthChange}
        defaultMonthDate={defaultMonthDate}
        inventoryData={inventoryDates}
        isPointsSelected={isPointsSelected}
        currentCurrency={currentCurrency}
        numberOfDays={selectedDays}
        dateRange={dateRange}
        propertyId={propertyId}
        interceptorResponse={interceptorResponse}
        preHeader={preHeader}
        selectedCurrency={selectedCurrency}
        setAlertMessage={setAlertMessage}
        returnApplicableAlert={returnApplicableAlert}
        isRedemption={isRedemption}
        fromCurrency={fromCurrency}
      />
    );
  };

  return showCalendar ? (
    <StyledAvailabilityCalendarDiv
      data-component-name="o-shop-availabilitycalendar"
      data-testid="availabilitycalendar"
      className={clsx('px-0 standard', 'py-md-4', isDesktop ? 'py-5' : 'mb-5')}
    >
      {alertMessage?.alertMessage && (
        <Messages
          className="adf-error-messages"
          messageType={alertMessage?.alertType}
          messageHeading={alertMessage?.alertHeading}
          messageText={alertMessage?.alertMessage}
        />
      )}
      {(isPointsSelected || isRedemption) && (
        <Text
          copyText={model?.['pointsLegalText']}
          customClass="points-legal-helper-text container"
          fontSize={isViewportM ? size.small : size.extraSmall}
          element={tags.div}
        />
      )}
      <ADFPageContextProvider>
        <TabComponent
          tabList={tabList}
          setSelectedTab={handleTabs}
          selectedTab={selectedTab}
          clickTrackingLoc="ADFCalendar"
          hideTabs={isPointsSelected || isRedemption}
          hidetabsAndContent={false}
          children={calendarComp()}
          classTabsEnabled={isPointsSelected && 'points-enabled'}
          customClass={'availability-tab-wrapper'}
          ariaRole="button"
        />
      </ADFPageContextProvider>
      {!isPointsSelected && !isRedemption && (
        <Text
          copyText={selectedTab === TOTAL_TAB ? model?.['avgTotalCostLegalText'] : model?.['avgNightlyCostLegalText']}
          customClass="legal-helper-text container"
          fontSize={isViewportM ? size.small : size.extraSmall}
          element={tags.div}
        />
      )}
    </StyledAvailabilityCalendarDiv>
  ) : (
    <SkeletonLoader></SkeletonLoader>
  );
};

export const AvailibilityCalendarConfig = {
  emptyLabel: 'AvailabilityCalendarComponent',
  isEmpty: false,
  resourceType: 'mi-aem-shop-spa/components/content/availabilitycalendar/v1/availabilitycalendar',
};

export const AvailabilityCalendarEditable = (props: any) => (
  <EditableComponent config={AvailibilityCalendarConfig} {...props}>
    <AvailabilityCalendar {...props} />
  </EditableComponent>
);
