import styled from 'styled-components';
import { toRem, contextualVariable, baseVariables } from '@marriott/mi-ui-library';

export const StyledCardSummaryContainer = styled.div`
  border-radius: ${toRem(14)};
  background: ${contextualVariable.brandedBackgroundColor};
  box-shadow: 0 ${toRem(4)} ${toRem(20)} 0 rgba(0, 0, 0, 0.07);
  overflow: hidden;
  .card-summary-content-section {
    margin: ${toRem(8)} ${toRem(16)};
    margin-right: ${toRem(4)};
    @media ${baseVariables.mediaQuery.sm} {
      margin: ${toRem(24)};
    }
    p {
      margin-bottom: ${toRem(0)};
    }
    .additional-link-cta {
      border-bottom: ${toRem(1)} solid ${contextualVariable.actionLinkBorderBottomColor};
    }
  }
  .cta-summary-btn {
    margin: ${toRem(24)};
    margin-left: ${toRem(0)};
    align-self: self-end;
    display: flex;
    padding-top: 0;
    align-items: center;
  }
  .cta-summary-chevron {
    margin: ${toRem(8)} ${toRem(16)};
    margin-left: ${toRem(0)};
    font-weight: ${baseVariables?.font?.fontWeightRegular};
  }
  img {
    object-fit: cover;
    height: 100%;
    max-width: ${toRem(100)};
    @media ${baseVariables.mediaQuery.sm} {
      max-width: ${toRem(240)};
    }
  }
`;
