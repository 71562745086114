import React, { FC, useEffect, useState, useRef } from 'react';
import clsx from 'clsx';

import { AccordionProps } from './Accordion.types';
import { StyledAccordion } from './Accordion.styles';
import { Button } from '../../atoms/Button';
import { Icon } from '../../atoms/Icon';
import { AccordionHiddenClasses } from '../../constants/ApplicationConstants';

export const Accordion: FC<AccordionProps> = props => {
  const {
    children,
    disableAccInDesktop = false,
    disableAccInTabletAndDesktop = false,
    styleclass,
    customClass,
    headerChildren,
    headerAriaLabel,
    enableAccordionSeparator = false,
    id,
    isOpen = false,
    custom_click_track_open_value,
    custom_click_track_closed_value,
    disableAccResize = false,
    enableFullWidthHeader = false,
    childrenClass,
    handleHeaderClick,
  } = props;

  const HEADING_SELECTOR = `.accordion__heading .accordion__icon`;
  const ICON_ARROW_UP = `icon-arrow-up`;
  const ICON_ARROW_DOWN = `icon-arrow-down`;

  const [ariaLabel, setAriaLabel] = useState('Accordion');

  const headingRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isOpen) {
      handleAccordionCollapse();
    } else {
      handleAccordionExpand();
    }
  }, [isOpen]);

  useEffect(() => {
    handleAccordionCollapse();
    if (!disableAccResize) {
      window?.addEventListener('resize', () => handleAccordionCollapse());
    }
  }, []);

  useEffect(() => {
    const headerElement = titleRef.current;
    if (headerElement) {
      setAriaLabel(headerAriaLabel || headerElement.textContent || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAccordionCollapse = () => {
    const sm = window.matchMedia('(max-width: 767px)');
    const md = window.matchMedia('(min-width: 768px) and (max-width: 991px)');
    const xl = window.matchMedia('(min-width: 992px)');
    const acc_body = listRef.current;
    const acc_header = headingRef.current;
    if (disableAccInDesktop && !isOpen) {
      (md.matches || sm.matches) && acc_body?.classList?.add('collapsed', 'hide-visibility');
      acc_header?.querySelector('.icon-arrow-up')?.classList?.replace('icon-arrow-up', 'icon-arrow-down');
      xl.matches && acc_body?.classList?.remove('collapsed', 'hide-visibility');
    } else if (disableAccInTabletAndDesktop && !isOpen) {
      sm.matches && acc_body?.classList?.add('collapsed', 'hide-visibility');
      acc_header?.querySelector('.icon-arrow-up')?.classList?.replace('icon-arrow-up', 'icon-arrow-down');
      (md.matches || xl.matches) && acc_body?.classList?.remove('collapsed', 'hide-visibility');
    } else if (!isOpen) {
      acc_body?.classList?.add('collapsed', 'hide-visibility');
      acc_header?.querySelector('.icon-arrow-up')?.classList?.replace('icon-arrow-up', 'icon-arrow-down');
    }
  };

  const handleAccordionExpand = () => {
    const acc_body = listRef.current;
    if (isOpen) {
      acc_body?.classList?.remove('collapsed', 'hide-visibility');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const accordionHeaderClick = (e: any) => {
    const list = listRef?.current;
    const headerIcon = e?.currentTarget?.parentElement?.querySelector(`${HEADING_SELECTOR}`);
    if (list?.classList?.contains('collapsed')) {
      list?.classList?.remove(...AccordionHiddenClasses);
      if (headerIcon?.classList?.contains(`${ICON_ARROW_UP}`)) {
        headerIcon?.classList?.replace(`${ICON_ARROW_UP}`, `${ICON_ARROW_DOWN}`);
      } else {
        headerIcon?.classList?.replace(`${ICON_ARROW_DOWN}`, `${ICON_ARROW_UP}`);
      }
      e?.currentTarget?.setAttribute('aria-expanded', 'true');
      /**
       * Click Tracking Value for open state of the accordion
       */
      custom_click_track_open_value &&
        e?.currentTarget?.setAttribute('custom_click_track_value', custom_click_track_open_value);
    } else if (!list?.classList?.contains('collapsed')) {
      list?.classList?.add(...AccordionHiddenClasses);
      if (headerIcon?.classList?.contains(`${ICON_ARROW_UP}`)) {
        headerIcon?.classList?.replace(`${ICON_ARROW_UP}`, `${ICON_ARROW_DOWN}`);
      } else {
        headerIcon?.classList?.replace(`${ICON_ARROW_DOWN}`, `${ICON_ARROW_UP}`);
      }
      e?.currentTarget?.setAttribute('aria-expanded', 'false');
      /**
       * Click Tracking Value to capture in closed state of accordion
       */
      custom_click_track_closed_value &&
        e?.currentTarget?.setAttribute('custom_click_track_value', custom_click_track_closed_value);
    }
    handleHeaderClick?.(id);
  };

  return (
    <StyledAccordion
      className={clsx(styleclass, customClass, 'acc')}
      disableAccInTabletAndDesktop={disableAccInTabletAndDesktop}
      disableAccInDesktop={disableAccInDesktop}
      enableAccordionSeparator={enableAccordionSeparator}
      enableFullWidthHeader={enableFullWidthHeader}
    >
      <div className={`accordion`}>
        <div
          className={clsx(
            'accordion__container',
            'p-0',
            `${disableAccInDesktop ? 'accordion_des--disabled' : disableAccInTabletAndDesktop ? '' : ''}`
          )}
        >
          <div
            className={clsx(
              'accordion__title',
              `${
                disableAccInDesktop ? 'd-lg-flex d-none' : disableAccInTabletAndDesktop ? 'd-md-flex d-none' : 'd-none'
              }`,
              `pb-2 mb-0`
            )}
            ref={titleRef}
          >
            {headerChildren}
          </div>
          <Button
            className={clsx([
              `accordion__heading`,
              `${
                disableAccInDesktop
                  ? 'd-lg-none d-sm-flex'
                  : disableAccInTabletAndDesktop
                  ? 'd-md-none d-sm-flex p-md-0'
                  : ``
              }`,
            ])}
            ariaLabel={ariaLabel}
            ariaExpanded={isOpen}
            callback={e => accordionHeaderClick(e)}
            id={`${id}_heading`}
            ref={headingRef}
          >
            <div className={clsx('accordion__title')}>{headerChildren}</div>
            <Icon iconClass={clsx('accordion__icon', isOpen ? ICON_ARROW_UP : ICON_ARROW_DOWN)}></Icon>
          </Button>
          <div
            className={clsx([
              `${
                disableAccInDesktop
                  ? 'accordion_des--disabled'
                  : disableAccInTabletAndDesktop
                  ? 'accordion_tab--disabled'
                  : ''
              }`,
              'accordion__list',
              childrenClass,
            ])}
            id={`${id}_body`}
            key={`accordion_item`}
            ref={listRef}
          >
            {children}
          </div>
        </div>
      </div>
    </StyledAccordion>
  );
};
