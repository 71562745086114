import { EditableComponent } from '@adobe/aem-react-editable-components';
import { CardLayered as CardLayeredComp, CardLayeredProps } from '@marriott/mi-ui-library';

export const CardLayeredConfig = {
  emptyLabel: 'CardLayered',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/cardlayered`,
};

export const CardLayered = (props: CardLayeredProps) => {
  // Style system classes are modified only for organism.
  function modifyStyleClass(styleClass: string | undefined) {
    const keywords = ['standard', 'inverse', 'alternate'];
    const hasKeyword = keywords.some(keyword => styleClass?.includes(keyword));

    if (!hasKeyword) {
      if (styleClass == undefined) {
        styleClass = 'inverse';
      } else {
        styleClass += ' inverse';
      }
    }

    return styleClass;
  }
  const cardLayeredProps = {
    ...props,
    styleclass: modifyStyleClass(props.styleclass),
  };
  return (
    <div className="p-0" data-testid="card-layered-1" data-component-name="o-common-cardlayered">
      <EditableComponent config={CardLayeredConfig} {...props}>
        <CardLayeredComp {...cardLayeredProps} />
      </EditableComponent>
    </div>
  );
};
