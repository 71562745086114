/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import { FC, KeyboardEvent, useEffect, useState } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';

import { MapAndRegionCardProps } from './MapAndRegionCard.types';
import { StyledMapAndRegionCard } from './MapAndRegionCard.styles';
import { HotelDirectoryMap } from './HotelDirectoryMap';
import { HotelDirectoryStaticDataContextProvider } from '../../modules/context';
import { RegionCardsWrapper } from './RegionCardsWrapper/RegionCardsWrapper';
import {
  changeRegionNames,
  getAppliedFiltersCount,
  getSearchQueryDataFromSession,
  searchCounterValue,
  transformFilterProperties,
  updateFiltersPayload,
  hasHDQueryParams,
  processFiltersFromQuery,
  updateSessionObjWithQueryParams,
} from './HotelDirectoryMap/HotelDirectoryMapHelper';
import { usePageContext } from '../../context';
import { MapAndRegionCardSkeletonLoader } from './MapAndRegionCardSkeletonLoader';
import {
  CustomSelectBlock,
  Heading,
  Messages,
  PillCollection,
  Types,
  useCheckBreakpoint,
  useWindowSize,
} from '@marriott/mi-ui-library';
import clsx from 'clsx';
import { useHotelDirectoryStore } from '../../modules/store/hotelDirectoryStore';
import { SearchFacetsList, HDQueryParamsToCheck } from '../../constants/Filters.constant';
import { DEFAULT_CENTER, TRACKING_CONST, propertiesToProcess } from '../../modules/constants/HotelDirectory.constants';
import { FilterProps } from '../../molecules/SearchFilterPanel/SearchFilterPanel.types';
import { AllRegionList } from './AllRegionList';
import { toRem } from '@marriott/mi-ui-library';
import { PillDataProps } from './MapAndRegionCard.types';

// Use named rather than default exports.
export const MapAndRegionCard: FC<MapAndRegionCardProps> = (props: MapAndRegionCardProps) => {
  const { model, pageLoaded, isViewportM, isStoryBook = false } = props;
  const [hotelDirectoryCounter, setHotelDirectoryCounter] = useState<number>(0);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(isStoryBook ? true : false);
  const { sessionData, currentLocale, headersData } = usePageContext();
  let sessionVal = sessionData?.data ? sessionData?.data : sessionData?.cacheData?.data;
  const queryParams: any = typeof window !== 'undefined' ? new URLSearchParams(window?.location?.search) : {};
  const hasFiltersInQuery: boolean = hasHDQueryParams(queryParams, HDQueryParamsToCheck);
  let newSessionData = getSearchQueryDataFromSession(sessionVal);
  const {
    hotelDirectoryHeading,
    hotelsLabel,
    hotelDirectoryTemporarilyUnavailableMsg,
    filterLabel,
    amenitiesLabel,
    brandsLabel,
  } = model;

  const [totalCountOnHeader, setTotalCountOnHeader] = useState('0');
  const [isSessionUpdateWithQueryFilter, setSessionWithQueryFilter] = useState<boolean>(false);
  const pageLevelAlert = useHotelDirectoryStore(
    (state: { showPageLevelAlert: any }) => state?.showPageLevelAlert
  )?.showPageLevelAlert;
  let filtersSessionVal = transformFilterProperties(newSessionData, propertiesToProcess);
  const [filtersPayload, setFiltersPayload] = useState<Array<{ type: string; dimensions: Array<string> }>>(
    updateFiltersPayload(filtersSessionVal)
  );
  let filterValCount = getAppliedFiltersCount(filtersPayload);

  const [filtersCount, setFiltersCount] = useState<number>(filterValCount);
  const { HOTEL_DIRECTORY, ALL_FILTERS, REGION_SECTION, AMENITIES_FILTERS, BRANDS_FILTERS } = TRACKING_CONST;
  const [filtersData, setFiltersData] = useState<Array<FilterProps>>();

  // get the amenities data in response
  const amenitiesData = filtersData?.filter((filter: any) => filter?.code === SearchFacetsList?.amenities);
  // get the brands data in response
  const brandsData = filtersData?.filter((filter: any) => filter?.code === SearchFacetsList?.brands);
  // check if amenities is selected in modal
  const isAmenitiesSelected = filtersPayload.some(
    (payload: any) => payload?.type === SearchFacetsList?.amenities && payload?.dimensions?.length > 0
  );
  // check if brand is selected in modal
  const isBrandsSelected = filtersPayload.some(
    (payload: any) => payload?.type === SearchFacetsList?.brands && payload?.dimensions?.length > 0
  );
  const [filterButtonClicked, setFilterButtonClicked] = useState<any>({
    allFilters: false,
    amenities: false,
    brands: false,
  });
  const [popupOpenState, setPopupOpenState] = useState<boolean>(false);

  // function to open the respective modal on filter button click
  const handlePillClick = (value: any) => {
    const checkIfPresent = value in filterButtonClicked;
    Object.keys(filterButtonClicked)?.forEach((item: any) => {
      if (item === value) {
        filterButtonClicked[item] = checkIfPresent;
      }
    });
    setFilterButtonClicked(filterButtonClicked);
    setPopupOpenState(true);
  };
  // set a state to populate filters pills
  const [pillsData, setPillsData] = useState<PillDataProps[]>([
    {
      label: filterLabel,
      value: 'allFilters',
      isActive: filtersCount > 0,
      showIcon: filtersCount === 0,
      iconClass: 'icon-dining-filter',
      count: filtersCount,
      custom_click_track_value: `${HOTEL_DIRECTORY} | ${ALL_FILTERS} | internal`,
      buttonClassName: 'd-flex align-items-center all-filters-button',
      id: 'allFilters',
      className: '',
    },
    {
      label: amenitiesLabel,
      value: 'amenities',
      isActive: isAmenitiesSelected,
      custom_click_track_value: `${HOTEL_DIRECTORY} | ${AMENITIES_FILTERS} | internal`,
      className: '',
      id: 'amenities',
    },
    {
      label: brandsLabel,
      value: 'brands',
      isActive: isBrandsSelected,
      custom_click_track_value: `${HOTEL_DIRECTORY} | ${BRANDS_FILTERS} | internal`,
      id: 'brands',
      className: '',
    },
  ]);
  const [openState, setOpenState] = useState(false);
  const [regionSelectorClicked, setRegionSelectorClicked] = useState(false);

  // Conditionally update sessionVal if filters are present in query parameters for first load
  useEffect(() => {
    if (hasFiltersInQuery) {
      const transformFilterQueryPropsFromURL = processFiltersFromQuery(queryParams);
      const filterPayload = updateFiltersPayload(transformFilterQueryPropsFromURL);
      sessionVal = updateSessionObjWithQueryParams(queryParams, sessionVal, true);
      newSessionData = getSearchQueryDataFromSession(sessionVal);
      filtersSessionVal = transformFilterProperties(newSessionData, propertiesToProcess);
      filterValCount = getAppliedFiltersCount(filterPayload);
      setFiltersPayload(filterPayload);
      setFiltersCount(filterValCount);
      setSessionWithQueryFilter(true);
    }
  }, []);

  // increase hash counter on load
  useEffect(() => {
    searchCounterValue(headersData, hotelDirectoryCounter, setHotelDirectoryCounter);
  }, []);

  useEffect(() => {
    regionSelectorClicked && setOpenState(true);
  }, [regionSelectorClicked]);

  const [center, setCenter] = useState(DEFAULT_CENTER);

  const handleSelectBlockKeyDown = (e: KeyboardEvent<HTMLElement>): void => {
    if (e.keyCode === 13) {
      setOpenState(!openState);
    }
  };
  const selectedRegion = useHotelDirectoryStore(
    (state: { selectedRegion: any }) => state.selectedRegion
  ).selectedRegion;
  return (
    <StyledMapAndRegionCard data-component-name="o-shop-mapsubregion" data-testid="mapsubregion" className="standard">
      <div className={clsx('container')}>
        <div className="hotelDirectoryHeading">
          <div className={clsx('align-items-center')}>
            <Heading
              fontSize={Types.size.small}
              element={Types.tags.h1}
              variation={Types.headingType.title}
              titleText={hotelDirectoryHeading}
              customClass={'m-0'}
              role="heading"
            ></Heading>
          </div>
          <div className={clsx('mb-3')}>
            {!isDataLoaded && totalCountOnHeader === '0' ? (
              <div className={clsx('d-flex', 'hotel-count-loader-container')}>
                <div
                  className={clsx('skeleton-loader-composite', 'image-place-loader-map')}
                  style={{
                    width: `${toRem(40)}`,
                    height: `${toRem(24)}`,
                    marginBottom: `${toRem(16)}`,
                  }}
                >
                  <img
                    src="https://cache.marriott.com/content/dam/marriott-digital/cy/us-canada/hws/h/hpnnb/en_us/logo/internal/assets/cy-hpnnb-grey-image-placeholder-34740.png?imbypass=on"
                    alt="loading"
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                    className={clsx('car-img', 'image-place-loader-map')}
                  ></img>
                </div>
                <span>{hotelsLabel}</span>
              </div>
            ) : (
              <Heading
                element={Types.tags.span}
                variation={Types.headingType.body}
                fontSize={Types.size.medium}
                titleText={totalCountOnHeader + ' ' + hotelsLabel}
                role="heading"
                aria-level="1"
              ></Heading>
            )}
          </div>
          {pageLevelAlert ? (
            <div className={clsx('text-left')}>
              <Messages messageType={'info'} messageHeading={hotelDirectoryTemporarilyUnavailableMsg} />
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div
          className={clsx(
            'd-flex',
            'justify-content-between',
            'all-filters-container',
            isViewportM ? 'align-items-center' : 'flex-column'
          )}
        >
          <PillCollection
            pills={pillsData}
            onChange={value => handlePillClick(value)}
            customClass={clsx('pill-collection')}
          />
          <div
            className={clsx('all-region-list-container', isViewportM ? '' : 'mt-2 pt-1')}
            onKeyDown={handleSelectBlockKeyDown}
          >
            <div
              onBlur={() => {
                openState && setRegionSelectorClicked(!regionSelectorClicked);
              }}
              className={clsx('row', 'map-region-selector-icon-wrapper')}
            >
              <div className="col map-region-selector-icon">
                <span className="icon-location"></span>
              </div>
              <CustomSelectBlock
                customButtonClass={'col pt-1 t-font-s button-label'}
                handleClick={() => {
                  !openState && setRegionSelectorClicked(!regionSelectorClicked);
                }}
                selectTitle={changeRegionNames(selectedRegion, model?.unitedStatesLabel, model?.australiaPILabel)}
                selectDescription={''}
                isColonSeparatorEnabled={false}
                changeArrowOnOpen={true}
                isExpanded={openState}
                customClickTrackValue={`${HOTEL_DIRECTORY} | ${REGION_SECTION} | internal`}
              ></CustomSelectBlock>
            </div>
            <HotelDirectoryStaticDataContextProvider value={model}>
              <AllRegionList
                openState={openState}
                setOpenState={setOpenState}
                setHotelDirectoryCounter={setHotelDirectoryCounter}
                hotelDirectoryCounter={hotelDirectoryCounter}
                custom_click_track_value={`${HOTEL_DIRECTORY} | ${REGION_SECTION} : ${selectedRegion?.label} | internal`}
                setCenter={setCenter}
              ></AllRegionList>
            </HotelDirectoryStaticDataContextProvider>
          </div>
        </div>
      </div>
      {pageLoaded ? (
        <HotelDirectoryStaticDataContextProvider value={model}>
          <HotelDirectoryMap
            hotelDirectoryCounter={hotelDirectoryCounter}
            setHotelDirectoryCounter={setHotelDirectoryCounter}
            hotelQueryData={newSessionData}
            currentLocale={currentLocale}
            totalCountOnHeader={totalCountOnHeader}
            setTotalCountOnHeader={setTotalCountOnHeader}
            filtersCount={filtersCount}
            filtersSessionVal={filtersSessionVal}
            filtersPayload={filtersPayload}
            setFiltersPayload={setFiltersPayload}
            setCenter={setCenter}
            center={center}
            setPillsData={setPillsData}
            isAmenitiesSelected={isAmenitiesSelected}
            isBrandsSelected={isBrandsSelected}
            setFiltersData={setFiltersData}
            filtersData={filtersData}
            popupOpenState={popupOpenState}
            setPopupOpenState={setPopupOpenState}
            filterButtonClicked={filterButtonClicked}
            amenitiesData={amenitiesData}
            brandsData={brandsData}
            setFilterButtonClicked={setFilterButtonClicked}
            setFiltersCount={setFiltersCount}
            setIsDataLoaded={setIsDataLoaded}
            isSessionUpdateWithQueryFilter={isSessionUpdateWithQueryFilter}
            {...props}
          ></HotelDirectoryMap>
          <RegionCardsWrapper
            hotelDirectoryCounter={hotelDirectoryCounter}
            setHotelDirectoryCounter={setHotelDirectoryCounter}
            isDataLoaded={isDataLoaded}
          ></RegionCardsWrapper>
        </HotelDirectoryStaticDataContextProvider>
      ) : (
        <div>
          <MapAndRegionCardSkeletonLoader></MapAndRegionCardSkeletonLoader>
        </div>
      )}
    </StyledMapAndRegionCard>
  );
};

export const MapAndRegionCardConfig = {
  emptyLabel: 'MapAndRegionCardComponent',
  isEmpty: () => false,
  resourceType: 'mi-aem-shop-spa/components/content/mapsubregion/v1/mapsubregion',
};

export const MapAndRegionCardEditable = (props: MapAndRegionCardProps) => {
  const { width } = useWindowSize();
  useEffect(() => {
    if (width) {
      setPageLoaded(true);
    }
  }, [width]);
  const isDesktopView = useCheckBreakpoint('viewportL');
  const isViewportM = useCheckBreakpoint('viewportM');
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);

  return (
    <EditableComponent config={MapAndRegionCardConfig} {...props}>
      {pageLoaded ? (
        <MapAndRegionCard
          {...props}
          pageLoaded={pageLoaded}
          isDesktopView={isDesktopView}
          isViewportM={isViewportM}
          isStoryBook={false}
        />
      ) : (
        <MapAndRegionCardSkeletonLoader></MapAndRegionCardSkeletonLoader>
      )}
    </EditableComponent>
  );
};
