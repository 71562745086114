import { EditableComponent } from '@adobe/aem-react-editable-components';
import {
  CardHorizontalFeature as CardHorizontalFeatureComp,
  CardHorizontalFeatureProps,
} from '@marriott/mi-ui-library';

export const CardHorizontalFeatureConfig = {
  emptyLabel: 'CardHorizontalFeature',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/common/cardhorizontalfeature`,
};

export const CardHorizontalFeature = (props: CardHorizontalFeatureProps) => (
  <div data-testid="card-horizontal-feature" data-component-name="o-common-cardhorizontalfeature">
    <EditableComponent config={CardHorizontalFeatureConfig} {...props}>
      <CardHorizontalFeatureComp {...props} />
    </EditableComponent>
  </div>
);
