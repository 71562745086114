import { EditableComponent } from '@adobe/aem-react-editable-components';

import { Mosaic as MosaicComp } from '@marriott/mi-ui-library';

export const MosaicConfig = {
  emptyLabel: 'Mosaic',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/mosaic`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Mosaic = (props: any) => {
  const { model } = props;
  return (
    <div className={model.styleclass} data-testid="mosaic-comp" data-component-name="o-common-mosaic">
      <div className="container">
        <EditableComponent config={MosaicConfig} {...props}>
          <MosaicComp
            image1={{
              dynamicMedia: model.dynamicMediaImage1,
              openInNewTab: model.image1CtaOpenInNewTab,
              headerText: model.image1LinkText,
              linkUrl: model.image1LinkUrl,
              altText: model.imgAltText1,
            }}
            image2={{
              dynamicMedia: model.dynamicMediaImage2,
              openInNewTab: model.image2CtaOpenInNewTab,
              headerText: model.image2LinkText,
              linkUrl: model.image2LinkUrl,
              altText: model.imgAltText2,
            }}
            image3={{
              dynamicMedia: model.dynamicMediaImage3,
              openInNewTab: model.image3CtaOpenInNewTab,
              headerText: model.image3LinkText,
              linkUrl: model.image3LinkUrl,
              altText: model.imgAltText3,
            }}
            content={{
              eyebrow: model.eyebrow,
              headerTag: model.headerTag,
              headerText: model.header,
              linkUrl: model.linkUrl,
              linkText: model.linkText,
              description: model.description,
            }}
            componentId={model.componentId}
          />
        </EditableComponent>
      </div>
    </div>
  );
};
