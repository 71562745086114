import React, { FC, useRef, useEffect, useState, RefObject } from 'react';
import clsx from 'clsx';

import { BannerTextProps } from './BannerText.types';
import { StyledBannerText } from './BannerText.styles';
import { Eyebrow } from '../../atoms/Eyebrow';
import { Icon } from '../../atoms/Icon';
import { Heading } from '../../atoms/Heading';
import { Link } from '../../atoms/Link';
import { Image } from '../../atoms/Image';
import { Button } from '../../atoms/Button';
import { headingType, size, expanded_constants, ButtonStyle } from '../../utils/enums/enums';
import { throttle } from '../../utils/helper';

export const BannerText: FC<BannerTextProps> = (props: BannerTextProps) => {
  const {
    assetVariation,
    contentBlockAlignment,
    eyebrow,
    headerText,
    headerTag,
    headerFontSize,
    horizontalRule,
    descriptionText,
    showShortDescription,
    ctaLink,
    ctaLinkText,
    openInNewTab = false,
    iconPath,
    iconAltText,
    dynamicMedia,
    trackingProperties,
    styleclass,
    componentId,
  } = props;

  const renditions = dynamicMedia?.renditions;
  const BannerTextCtaBtnRef = useRef(null) as unknown as React.MutableRefObject<HTMLInputElement>;
  const BannerTextDescriptionRef = useRef<HTMLDivElement>(null);
  const customAttributes = trackingProperties?.enableScrollingBehavior ? { 'data-section-tracking': componentId } : {};

  const ELLIPSIS_2LINESHEIGHT = 48;

  const { SEE_LESS, SEE_MORE } = expanded_constants;
  const [showLinkText, setShowLinkText] = useState(SEE_MORE);
  const [isShowMoreLinkHide, setIsShowMoreLinkHide] = useState(showShortDescription);

  function handleExpandButton(e: Event) {
    e.preventDefault();
    setShowLinkText(prevText => {
      if (prevText === SEE_LESS) return SEE_MORE;
      else if (prevText === SEE_MORE) return SEE_LESS;
      return SEE_LESS;
    });
  }

  const isEllipsisAdded = (ref: RefObject<HTMLDivElement>) => {
    if (ref.current) {
      return (
        ref.current.scrollHeight > ref.current.clientHeight ||
        !(ref.current.clientHeight <= ELLIPSIS_2LINESHEIGHT && ref.current.scrollHeight <= ELLIPSIS_2LINESHEIGHT)
      );
    }
    return false;
  };

  useEffect(() => {
    function handleSeMoreOnResize() {
      if (showShortDescription) {
        if (BannerTextDescriptionRef.current) {
          setIsShowMoreLinkHide(isEllipsisAdded(BannerTextDescriptionRef));
        }
      } else {
        setIsShowMoreLinkHide(false);
      }
    }

    const throttledResizeHandler = throttle(handleSeMoreOnResize, 500);
    const observer = new ResizeObserver(throttledResizeHandler);
    observer.observe(document.body);
  }, [showShortDescription, showLinkText]);

  const brandCode = iconPath?.includes('brands') && `${iconPath.replace('brands/', '')}`;
  const brandPortfolioName =
    iconPath?.includes('portfolio') && `${iconPath.replace('brands/portfolio/portfolio-icon-', '')}`;

  const iconFontClasses = clsx({
    'icon-m': true,
    [`portfolio-icon-${brandPortfolioName}`]: brandPortfolioName,
    [`brand-logo-${brandCode}`]: !brandPortfolioName && brandCode,
    [`${iconPath} icon-size`]: !brandPortfolioName && !brandCode,
    'icon-decorative': styleclass?.includes('icon-decorative') && !brandPortfolioName && !brandCode,
  });
  return (
    <StyledBannerText
      className={clsx(styleclass)}
      contentBlockAlignment={contentBlockAlignment}
      brandCode={brandPortfolioName || brandCode || ''}
    >
      <div className={clsx('bannertext container-sm')} data-testid="banner-text-content" {...customAttributes}>
        {assetVariation === 'iconfont' && (
          <div className="bannertext_icon">
            <Icon iconClass={iconFontClasses} aria-live="polite" ariaLabel={iconAltText} />
          </div>
        )}
        {assetVariation === 'image' && renditions && (
          <div className="bannertext_img">
            <Image
              altText={dynamicMedia?.altText}
              renditions={renditions}
              dynamic={dynamicMedia?.dynamic}
              defaultImageURL={dynamicMedia?.assetPath}
            />
          </div>
        )}
        {eyebrow && <Eyebrow text={eyebrow} />}
        {headerText && (
          <Heading
            element={headerTag}
            variation={headingType.title}
            titleText={headerText}
            fontSize={headerFontSize?.split('-')[2] as size.medium | size.small}
            disableCustomClickTrack
            customClass={clsx('bannertext_heading bannertext_text-align', 'm-0')}
          />
        )}
        {horizontalRule && <span className={'seperator t-horizontal-vertical-rule'}></span>}
        {descriptionText && (
          <div
            className={clsx(
              'bannertext_desccontent bannertext_text-align',
              showShortDescription && showLinkText === SEE_MORE ? 'small-margin' : ''
            )}
          >
            <div
              dangerouslySetInnerHTML={{ __html: descriptionText }}
              ref={BannerTextDescriptionRef}
              className={clsx(showShortDescription && showLinkText === SEE_MORE ? 'm-ellipsis-2lines' : '', 't-body-m')}
            ></div>
            {isShowMoreLinkHide && (
              <Link
                text={''}
                linkClassName={clsx('t-label-alt-m bannertext_link')}
                linkHref={''}
                target="_blank"
                callback={handleExpandButton}
                tabIndex={0}
                id={componentId}
              >
                <span className="bannertext_linktext" aria-live="polite">
                  {showLinkText}
                </span>
              </Link>
            )}
          </div>
        )}
        {ctaLink && (
          <Button
            isLink
            href={ctaLink}
            className={clsx(
              'bannertext_button m-button-s mt-3 mb-4',
              openInNewTab ? ButtonStyle.PRIMARY_BUTTON_EXTERNAL : ButtonStyle.PRIMARY_BUTTON
            )}
            target={`${openInNewTab ? '_blank' : ''}`}
            linkType={`${openInNewTab ? 'external' : ''}`}
            rel={`${openInNewTab ? 'noopener noreferrer' : ''}`}
            ref={BannerTextCtaBtnRef}
            buttonCopy={ctaLinkText}
            trackingProperties={trackingProperties}
          />
        )}
      </div>
    </StyledBannerText>
  );
};
