// Imports for external libraries go here.
import React, { FC } from 'react';
import { MarkerClusterer } from '@react-google-maps/api';
// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.

import { MapMarkerClusterProps } from './MapMarkerCluster.types';
import { StyledMapMarkerCluster } from './MapMarkerCluster.styles';
export const MapMarkerCluster: FC<MapMarkerClusterProps> = ({ ...props }) => {
  return (
    <StyledMapMarkerCluster>
      <MarkerClusterer {...props}></MarkerClusterer>
    </StyledMapMarkerCluster>
  );
};
