import { FC, useRef } from 'react';

import { Heading } from '../../atoms/Heading';
import { Text } from '../../atoms/Text';
import { Button } from '../../atoms/Button';
import { Image } from '../../atoms/Image';
import { Eyebrow } from '../../atoms/Eyebrow';
import { headingType, tags, size } from '../../utils/enums/enums';
import { CardHorizontalFeatureProps } from './CardHorizontalFeature.types';

import { StyledComp } from './CardHorizontalFeature.styles';
import { ButtonStyle } from '../../utils/enums/enums';
import clsx from 'clsx';

/**
 * `CardHorizontalFeature` is a functional React component that renders a customizable card feature.
 *
 * Props:
 * - `eyebrowtext`: Text displayed at the top of the card.
 * - `header`: The main header text of the card.
 * - `description`: The main content or description of the card.
 * - `imageAlignment`: Determines the alignment of the image.
 * - `headerTag`: The HTML tag to be used for the header.
 * - `contentBlockAlignment`: Determines the alignment of the content block.
 * - `linkText`: The text for the link button.
 * - `linkUrl`: The URL that the link button points to.
 * - `ctaType`: The type of call-to-action button.
 * - `openInNewTab`: Boolean indicating whether the link should open in a new tab.
 * - `trackingProperties`: Object containing properties for tracking user interaction.
 * - `styleclass`: Custom class name for styling.
 * - `dynamicMedia`: Object containing information about the dynamic media.
 * - `headerFontSize`: The font size for the header.
 * - `descriptionCustomClass`: Custom class name for the description text.
 * - `headerCustomClass`: Custom class name for the header text.
 * - `custom_click_track_value`: Custom value for tracking click events.
 *
 * The component includes an image, a heading, text, and a button, all styled and configured according to the props.
 */

export const CardHorizontalFeature: FC<CardHorizontalFeatureProps> = ({
  eyebrowtext,
  header,
  description,
  imageAlignment,
  headerTag = tags.h2,
  contentBlockAlignment,
  linkText,
  linkUrl,
  ctaType,
  openInNewTab = false,
  trackingProperties,
  styleclass,
  dynamicMedia,
  headerFontSize = size.small,
  descriptionCustomClass,
  headerCustomClass,
  custom_click_track_value = '',
  ...props
}) => {
  const getButtonStyle = (ctaType: string, openInNewTab: boolean): string => {
    switch (ctaType) {
      case 'primaryButton':
        return openInNewTab ? ButtonStyle.PRIMARY_BUTTON_EXTERNAL : ButtonStyle.PRIMARY_BUTTON;
      case 'secondaryButton':
        return openInNewTab ? ButtonStyle.SECONDARY_BUTTON_EXTERNAL : ButtonStyle.SECONDARY_BUTTON;
      case 'tertiaryButton':
        return openInNewTab ? ButtonStyle.TERTIARY_BUTTON_EXTERNAL : ButtonStyle.TERTIARY_BUTTON;
      default:
        return '';
    }
  };
  const renditions = dynamicMedia?.renditions;

  const hasButtonMeta = linkText && linkUrl && linkText.length > 0 && linkUrl.length > 0;

  const buttonTypeClasses = getButtonStyle(ctaType || '', openInNewTab);

  const textAlignClasses = contentBlockAlignment === 'center' ? 'text-center' : 'text-left';

  const CardHorizontalFeatureRef = useRef(null) as unknown as React.MutableRefObject<HTMLInputElement>;

  const customAttributes = trackingProperties?.enableScrollingBehavior
    ? { 'data-section-tracking': props?.componentId }
    : {};

  return (
    <StyledComp
      newTab={openInNewTab}
      alignElement={contentBlockAlignment}
      imgAlignment={imageAlignment}
      className={`${styleclass ? styleclass : ''}`}
      {...customAttributes}
    >
      <div className="col-xs-12 col-md-6 col-xl-7 p-0 img-container">
        <Image
          customClass="feature-img"
          altText={dynamicMedia?.altText}
          renditions={renditions}
          dynamic={dynamicMedia?.dynamic}
          defaultImageURL={dynamicMedia?.assetPath}
        />
      </div>
      <div className={`content ${textAlignClasses} col-xs-12 col-md-6 col-xl-5 t-background-color`}>
        {eyebrowtext && <Eyebrow text={eyebrowtext} />}
        <Heading
          customClass={clsx('feature-header', headerCustomClass)}
          titleText={header}
          variation={headingType.title}
          fontSize={headerFontSize}
          element={headerTag}
        />
        {description && (
          <Text
            customClass={clsx('feature-text t-font-alt-s', descriptionCustomClass)}
            copyText={description}
            fontSize={size.small}
            element={tags.div}
          />
        )}

        {hasButtonMeta && (
          <Button
            isLink
            href={linkUrl}
            className={`${buttonTypeClasses} ${ctaType !== 'tertiaryButton' ? ' m-button-s' : ''} feature-button `}
            target={openInNewTab === true ? '_blank' : ''}
            trackingProperties={trackingProperties}
            ref={CardHorizontalFeatureRef}
            ariaLabel={linkText}
            custom_click_track_value={custom_click_track_value}
          >
            {linkText}
          </Button>
        )}
      </div>
    </StyledComp>
  );
};
