/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  monthsArray,
  METERSTOMILES,
  METERSTOKM,
  ROOM_THRESHOLD_1,
  ROOM_THRESHOLD_2,
  RANGE_4_9,
  RANGE_10_25,
  RANGE_26_PLUS,
} from '../../constants/lib/constants';
import { AEMAlertBodyProps, AlertMessageBodyProps } from '../../organisms/SearchForm/SearchForm.types';
import { logger } from '../index';

export function isDateAfterToday(dateString: string): boolean {
  const year = +dateString.substring(0, 4);
  const month = +dateString.substring(5, 7);
  const day = +dateString.substring(8, 10);
  const date = new Date(year, month - 1, day);
  return new Date(date.toDateString()) > new Date(new Date().toDateString());
}

export function getRoomRange(numofRooms: string): string {
  const numRooms = parseInt(numofRooms);

  if (numRooms < ROOM_THRESHOLD_1) {
    return numRooms.toString();
  } else if (numRooms < ROOM_THRESHOLD_2) {
    return RANGE_4_9;
  } else if (numRooms <= 25) {
    return RANGE_10_25;
  } else {
    return RANGE_26_PLUS;
  }
}

/*
To convert phone number format for DE & ZH domain
*/
const convertPhoneToDEAndZH = (phoneNumber: string): string => {
  let parts;
  const index = phoneNumber?.indexOf(' ');
  if (index === -1) {
    parts = [phoneNumber, ''];
  }
  parts = [phoneNumber?.slice(0, index), phoneNumber?.slice(index + 1)];

  return parts
    ? parts[0] +
        ' ' +
        parts[1]
          .replaceAll(' ', '')
          .replaceAll('-', '')
          .replace(/(\d{3})(\d{4})/, '$1 $2')
    : '';
};

/*
To format phone number format for DE domain , ZH Domain and US domain
*/ export const formatPhoneNumber = (phoneNumberString: string, lang: string): string => {
  const { log } = logger({})('formatPhoneNumber');
  log.debug(
    `[PerfTesting][SMHotelGalleryTitle] lang:${lang} Entering the formatPhoneNumber function at time: ${new Date().getHours()} ${new Date().getMinutes()} ${new Date().getSeconds()} ${new Date().getMilliseconds()}`
  );
  let returnedNumber = '';
  switch (lang) {
    case 'de-DE':
    case 'zh-TW':
      log.debug(
        `[PerfTesting][SMHotelGalleryTitle] lang:${lang} Exiting the formatPhoneNumber function at time: ${new Date().getHours()} ${new Date().getMinutes()} ${new Date().getSeconds()} ${new Date().getMilliseconds()}`
      );
      returnedNumber = convertPhoneToDEAndZH(phoneNumberString);
      return returnedNumber;
    default:
      if (phoneNumberString?.includes('+')) {
        if (phoneNumberString.replaceAll(' ', '').replaceAll('-', '')?.split('')?.length > 12) {
          returnedNumber = phoneNumberString
            .replaceAll(' ', '')
            .replaceAll('-', '')
            .replace(/(\d{2})(\d{3})(\d{4})/, '$1 $2 $3');
        } else {
          returnedNumber = phoneNumberString
            .replaceAll(' ', '')
            .replaceAll('-', '')
            .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1 $2-$3-$4');
        }
      }
      log.debug(
        `[PerfTesting][SMHotelGalleryTitle] lang:${lang} Exiting the formatPhoneNumber function at time: ${new Date().getHours()} ${new Date().getMinutes()} ${new Date().getSeconds()} ${new Date().getMilliseconds()}`
      );
      return returnedNumber;
  }
};

export const formatNumberByLocale = (numb: number | string, lang: string): string => {
  const { log } = logger({})('formatNumberByLocale');
  log.debug(
    `[PerfTesting][utils] Entering the formatNumberByLocale lang:${lang} function at time: ${new Date().getHours()} ${new Date().getMinutes()} ${new Date().getSeconds()} ${new Date().getMilliseconds()}`
  );
  const inputStr = numb ?? '';
  // convert input number to locale string according to the lang
  // const str = Number(inputStr).toLocaleString(lang || '');
  let str = '';
  switch (lang) {
    case 'ko-KR':
    case 'ja-JP':
    case 'it-IT':
    case 'fr-FR':
    case 'de-DE':
    case 'ru-RU':
      str = Number(inputStr)?.toLocaleString(lang || '', { maximumFractionDigits: 2 });
      break;
    case 'pt-BR':
      str = Number(inputStr)?.toLocaleString(lang || '', { maximumFractionDigits: 3 });
      break;
    case 'es-ES':
      str = Number(inputStr)?.toLocaleString(lang || '', { maximumFractionDigits: 2, useGrouping: true });
      break;
    case '':
    case undefined:
      str = Number(inputStr)?.toLocaleString();
      break;
    default:
      str = Number(inputStr)?.toLocaleString(lang || '');
      break;
  }
  log.debug(
    `[PerfTesting][utils] Exiting the formatNumberByLocale lang:${lang} function at time: ${new Date().getHours()} ${new Date().getMinutes()} ${new Date().getSeconds()} ${new Date().getMilliseconds()}`
  );
  return str;
};

/**
 * Function creates compact format for a given number based on the locale
 *
 * @param numb | string | undefined
 * @param locale | string
 * @param compactFormatLiteral | any
 * @returns string
 */
export function getCompactFormat(numb: number | string | undefined, locale: string, compactFormatLiteral: any): string {
  if (!numb) return '';
  if (Number(numb) < 1000) {
    return numb?.toString();
  }

  const numberLength = Math.trunc(Number(numb)).toString().length;
  const isMillion = !(numberLength > 3 && numberLength < 7);
  let result = '';
  switch (locale) {
    case 'ko-KR':
    case 'ja-JP':
      result = isMillion
        ? Number(Number(numb) / 1000000).toLocaleString(locale, { maximumFractionDigits: 1 }) +
          compactFormatLiteral?.millionLabel
        : Number(numb).toLocaleString(locale, { maximumFractionDigits: 1 });
      break;
    case 'en-US':
      result =
        Number(Number(numb) / (isMillion ? 1000000 : 1000)).toLocaleString(locale, { maximumFractionDigits: 1 }) +
        (isMillion ? compactFormatLiteral?.millionLabel : compactFormatLiteral?.thousandLabel);
      break;
    case 'it-IT':
    case 'fr-FR':
    case 'de-DE':
    case 'pt-BR':
    case 'ru-RU':
      result =
        Number(Number(numb) / (isMillion ? 1000000 : 1000)).toLocaleString(locale, { maximumFractionDigits: 1 }) +
        ' ' +
        (isMillion ? compactFormatLiteral?.millionLabel : compactFormatLiteral?.thousandLabel);
      break;
    case 'es-ES':
      result =
        Number(Number(numb) / (isMillion ? 1000000 : 1000)).toLocaleString(locale, {
          maximumFractionDigits: 1,
          useGrouping: true,
        }) +
        ' ' +
        (isMillion ? compactFormatLiteral?.millionLabel : compactFormatLiteral?.thousandLabel);
      break;
    default:
      result = numb?.toString();
      break;
  }
  return result;
}

// TODO: Please add code comment explaining what this function does.
export function dateReturner(dateString: string): string {
  const monthIndex = (+dateString.substring(5, 7) - 1).toString();
  const year = +dateString.substring(0, 4);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const monthMapping: any = monthsArray;
  const displayedMonth = monthMapping?.[monthIndex]?.['monthLabel'] ?? '';
  return `${displayedMonth} ${year}`;
}

export function getCalculatedDistance(dist: number | undefined, km: boolean, lang?: string): string | null {
  /** calculated distance for km/ miles */
  if (dist) {
    if (km) {
      // convert meters to km
      return formatNumberByLocale((dist / METERSTOKM).toFixed(1), lang || '');
    } else {
      // convert meters to miles
      return formatNumberByLocale((dist / METERSTOMILES).toFixed(1), lang || '');
    }
  }
  return null;
}

// callback function that will be used by the replace function in getFormattedErrorKey
// used to remove full stops and convert to camel case
function replacer(match: string): string {
  return match?.[1]?.toUpperCase();
}

// function that accepts dot separated string and returns camel cased key
function getFormattedErrorKey(input: string) {
  return input?.toLowerCase()?.replace(/\../g, replacer);
}

export const returnApplicableAlert = (matchKey: string, alertBody: AEMAlertBodyProps[]) => {
  const returnedAlert: any = {};
  const shortlistedAlert =
    alertBody &&
    alertBody?.filter((alert: any) => alert?.alertMsgTypeName?.toLowerCase()?.trim() === matchKey?.toLowerCase())?.[0];
  returnedAlert['alertMessage'] = shortlistedAlert
    ? shortlistedAlert?.alertCodeListModel[0]?.['alertMessageLabel']
    : '';
  returnedAlert['alertCode'] = shortlistedAlert ? shortlistedAlert?.alertMsgTypeName : '';
  returnedAlert['alertType'] = shortlistedAlert ? shortlistedAlert?.alertMsgType : '';
  returnedAlert['linkText'] = shortlistedAlert?.alertCodeListModel?.[0]?.alertLinkText ?? '';
  returnedAlert['messageRedirection'] = shortlistedAlert?.alertCodeListModel?.[0]?.alertMessageRedirection ?? '';
  returnedAlert['messageLink'] = shortlistedAlert?.alertCodeListModel?.[0]?.alertMessageLink ?? '';
  returnedAlert['alertLinkTextLabel'] = shortlistedAlert?.alertCodeListModel?.[0]?.alertLinkTextLabel ?? '';
  returnedAlert['stringNeedsToBeSplit'] = shortlistedAlert
    ? shortlistedAlert?.alertCodeListModel[0]?.['stringNeedsToBeSplit']
    : '';
  return returnedAlert;
};

export function displaySessionBasedAlerts(alertBody: AEMAlertBodyProps[], errorMessageKey: any) {
  let applicableAlert: AlertMessageBodyProps = {};
  let errorKey = '';
  if (errorMessageKey) {
    const sessionErrorKey = errorMessageKey;
    const splitErrorMessages = ('|' + sessionErrorKey)?.split('|');
    errorKey = getFormattedErrorKey(splitErrorMessages[splitErrorMessages?.length - 1] ?? '');
  }
  if (errorKey !== '' || errorKey !== undefined) {
    applicableAlert = returnApplicableAlert(errorKey, alertBody);
    if (applicableAlert && applicableAlert?.alertCode) {
      return applicableAlert;
    }
  }
  return {};
}

export function convertDateToTextFormat(inputDate: string, monthsArray: string[]): string {
  const dateSegments = inputDate.split('-');
  const month = Number(dateSegments[0]);
  const outputDate = monthsArray[month - 1] + ' ' + dateSegments[1] + ' ' + dateSegments[2];
  return outputDate;
}
