import React from 'react';
import { MapProps, MapRefs } from '@uiw/react-baidu-map';

export type MapComponentsProps = {
  navigationProps?: Record<string, unknown>;
  mapControlProps?: Record<string, unknown>;
  children?: React.ReactNode;
  mapTypeControl?: boolean;
  navigationControl?: boolean;
  panoramaControl?: boolean;
};

export type BaiduMapProps = MapComponentsProps & {
  apiKey: string;
  center: string | BMap.Point;
  zoom?: number;
  style?: React.CSSProperties;
  mapProps?: MapProps;
  getMapRef?: (map: MapRefs) => void;
};

export type BaiduMapContainerProps = BaiduMapProps;

export type Point = {
  x: number;
  y: number;
};

type BasicInformation = {
  latitude: number;
  longitude: number;
  currency?: string;
};

export interface SearchResultDataNodeType {
  property: { basicInformation: BasicInformation };
  basicInformation: BasicInformation;
}

export interface SearchResultDataNode {
  index?: number;
  node: SearchResultDataNodeType;
}

// constant enum from https://lbsyun.baidu.com/jsdemo.htm#TranslateggTobd
export enum COORD_STANDARD {
  WGS84 = 1,
  WGS84_MC,
  GCJ02,
  GCJ02_MC,
  BD09,
  BD09_MC,
  MAPBAR,
}
