import React from 'react';
import { BadgeProps } from './Badge.types';

export const Badge: React.FC<BadgeProps> = ({
  badgeVariation,
  badgeText,
  isInlineMerch = false,
  badgeIcon,
  customClass = '',
}) => {
  return (
    <>
      {badgeVariation === 'inline' && (
        <span
          className={`${isInlineMerch ? 'm-badge-inline-merch' : 'm-badge-inline'} t-overline-normal ${customClass}`}
        >
          {badgeText}
        </span>
      )}
      {badgeVariation === 'overlay' && (
        <div className={`m-badge-overlay-merch t-overline-normal ${customClass}`}>
          {badgeIcon && <span className={badgeIcon}></span>}
          {badgeText}
        </div>
      )}
    </>
  );
};
