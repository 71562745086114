import { EditableComponent } from '@adobe/aem-react-editable-components';
import {
  Button,
  Label,
  Types,
  IconCollection,
  IconCollectionAlignment,
  IconCollectionVariation,
} from '@marriott/mi-ui-library';
import { StyledBrandRibbon } from './BrandRibbon.styles';
import { BrandRibbonProps } from './BrandRibbon.types';
import { linkLists, BrandribbonVariation } from './BrandRibbon.types';
import { FC } from 'react';
import clsx from 'clsx';

export const BrandRibbonComp: FC<BrandRibbonProps> = (props: BrandRibbonProps) => {
  const {
    componentId,
    brandLogoTag,
    logoLinkText,
    ctaLink,
    trackingProperties,
    categorylists,
    variations = BrandribbonVariation.PortfolioCollection,
  } = props;

  const customAttributes = trackingProperties?.enableScrollingBehavior ? { 'data-section-tracking': componentId } : {};

  return (
    <StyledBrandRibbon
      data-component-name="o-common-brandribbon"
      id={componentId}
      data-testid="brand-ribbon"
      {...customAttributes}
      className={clsx({ 'brand-ribbon-extra-pt': brandLogoTag })}
    >
      {variations === BrandribbonVariation?.PortfolioCollection ? (
        <div className="brands-ribbon-inner-container">
          <div className="brands-ribbon-logo" data-testid="brand-ribbon-header">
            <Button isLink={true} href={ctaLink} className={`${brandLogoTag}`} ariaLabel={logoLinkText}>
              <span className="sr-only">{logoLinkText}</span>
            </Button>
          </div>
          {categorylists?.map((list: linkLists) => (
            <div className="row" key={list.categorylabel}>
              <Label
                labelText={list.categorylabel}
                element={Types.tags.h3}
                size={Types.size.extraSmall}
                customClass={'category col-12 col-lg-1'}
              />
              <div className="cardLinks col-12 col-lg-11" role="region">
                <IconCollection
                  variation={IconCollectionVariation.LogoLinks}
                  alignment={IconCollectionAlignment.Horizontal}
                  IconCollectionData={list.linklists}
                  trackingProperties={trackingProperties}
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="cardLinks navBrands" role="region" data-testid="portfolio-brands">
          <IconCollection
            enableLinkClsName={BrandribbonVariation.PortfolioBrands ? true : false}
            variation={IconCollectionVariation.LogoLinks}
            alignment={IconCollectionAlignment.Horizontal}
            IconCollectionData={categorylists?.[0].linklists}
            trackingProperties={trackingProperties}
          />
        </div>
      )}
    </StyledBrandRibbon>
  );
};

export const BrandRibbonConfig = {
  emptyLabel: 'BrandRibbon',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/brandribbon`,
};

const BrandRibbonContainer = (props: BrandRibbonProps) => {
  const { model } = props;
  return model?.styleclass?.includes('fullbleed') ? (
    <div className={`${props?.model?.styleclass}`}>
      <div className="container">
        <BrandRibbonComp {...props} />
      </div>
    </div>
  ) : (
    <div className={clsx([props?.model?.styleclass ? props?.model?.styleclass : '', 'container'])}>
      <BrandRibbonComp {...props} />
    </div>
  );
};

export const BrandRibbon = (props: BrandRibbonProps) => (
  <div data-testid="brand-ribbon-org">
    {props.cqPath?.includes('experiencefragment') ? (
      <BrandRibbonContainer {...props} {...props.model} />
    ) : (
      <EditableComponent config={BrandRibbonConfig} {...props}>
        <BrandRibbonContainer {...props} />
      </EditableComponent>
    )}
  </div>
);
