/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, Key, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Types, Heading, Accordion, Link, Messages, useCheckBreakpoint, Image } from '@marriott/mi-ui-library';
import { CARIBBEAN_LABEL, SELECTED_REGION, TRACKING_CONST } from '../../modules/constants/HotelDirectory.constants';
import { useHotelDirectoryStore } from '../../modules/store/hotelDirectoryStore';
import { StyledRegionCard } from './RegionCard.styles';
import { RegionCardProps } from './RegionCard.types';
import RegionItem from '../RegionItem/RegionItem';
import { formatNumberByLocale } from '../../utils/src/utils';
import { usePageContext } from '../../context';
import { useHotelDirectoryStaticDataContext } from '../../modules/context';
import {
  changeRegionNames,
  formatExitURL,
  searchCounterValue,
} from '../../organisms/MapAndRegionCard/HotelDirectoryMap/HotelDirectoryMapHelper';

export const RegionCard: FC<RegionCardProps> = ({
  region,
  name,
  code,
  count,
  lat,
  lon,
  customLabel,
  hotelDirectoryCounter,
  setHotelDirectoryCounter,
}) => {
  const { currentLocale, headersData } = usePageContext();

  const setHotelDirectoryState = useHotelDirectoryStore(
    (state: { setHotelDirectoryState: any }) => state.setHotelDirectoryState
  );
  const stateORCountryList = useHotelDirectoryStore((state: { stateORCountryList: any }) => state.stateORCountryList);
  const selectedRegion = useHotelDirectoryStore((state: { selectedRegion: any }) => state.selectedRegion);
  const [localStateORCountryList, setLocalStateOrCountryList] = useState<Array<React.ReactElement>>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isFiltersApplied, setIsFiltersApplied] = useState<string>('false');
  const { HOTEL_DIRECTORY, REGION_CARD } = TRACKING_CONST;
  const {
    hotelDirectoryNoHotelsMsg,
    hotelDirectoryTemporarilyUnavailableMsg,
    viewAllHotelsLabel,
    hotelsLabel,
    regionImages,
    unitedStatesLabel,
    australiaPILabel,
  } = useHotelDirectoryStaticDataContext();
  const isViewportM = useCheckBreakpoint('viewportM');
  const pageLevelAlert = useHotelDirectoryStore(
    (state: { showPageLevelAlert: any }) => state?.showPageLevelAlert
  )?.showPageLevelAlert;

  // function to get flag from the session data
  function getFiltersFromSessionStorage() {
    let isFiltersAppliedFromSession = 'false';
    if (sessionStorage.getItem('isFiltersApplied')) {
      isFiltersAppliedFromSession = sessionStorage.getItem('isFiltersApplied') ?? 'false';
    }
    setIsFiltersApplied(isFiltersAppliedFromSession);
  }

  useEffect(() => {
    if (stateORCountryList?.stateORCountryList?.length && stateORCountryList?.stateORCountryList[0]?.count > 0) {
      let results = [];
      // get stateORCountryList from the store and make a list of top 8 to show in the region card
      if (
        !(
          stateORCountryList?.stateORCountryList.length === 1 &&
          stateORCountryList?.stateORCountryList[0].label === name
        )
      ) {
        // loop through the full list and construct the markup with RegionItem components
        results = stateORCountryList?.stateORCountryList?.map((each: any, idx: Key | null | undefined) => (
          <RegionItem
            name={each?.label}
            count={each?.count}
            key={idx}
            code={each?.code}
            regionCode={selectedRegion?.selectedRegion?.code}
            isFiltersApplied={isFiltersApplied}
            custom_click_track_value={`${HOTEL_DIRECTORY} |  Region Card: ${selectedRegion?.selectedRegion?.label}: ${each?.label} | internal`}
          ></RegionItem>
        ));
      }
      setLocalStateOrCountryList(results);
    } else {
      setLocalStateOrCountryList([]);
    }
    setLoading(false);
    getFiltersFromSessionStorage();
  }, [stateORCountryList]);

  // Function is called when user clicks on region card
  const handleCardClick = () => {
    if (selectedRegion?.selectedRegion?.code !== code) {
      searchCounterValue(headersData, hotelDirectoryCounter, setHotelDirectoryCounter);
      // Changing the selectedRegion in store
      setLoading(true);
      setHotelDirectoryState([SELECTED_REGION], {
        [SELECTED_REGION]: {
          [SELECTED_REGION]: {
            code: code,
            label: name,
            count: count,
            latitude: lat,
            longitude: lon,
            customLabel: customLabel,
          },
        },
      });
    }
  };

  // get image for the specific region
  const regionImage = regionImages?.filter((img: any) => img?.regionCode === code)[0];

  return (
    <StyledRegionCard className={clsx('container', 'px-0')} onClick={handleCardClick} role="button">
      <div className={clsx('region-card-container', 'px-0')}>
        <div className={clsx('accordion-wrapper')}>
          <Accordion
            custom_click_track_open_value={`${HOTEL_DIRECTORY} | ${REGION_CARD}: ${name} | internal`}
            custom_click_track_closed_value={`${HOTEL_DIRECTORY} | ${REGION_CARD}: ${name} | internal`}
            headerChildren={[
              <div className={clsx('d-flex', 'my-3', 'region-card-parent-container')}>
                <div className={clsx('region-card-placeholder', 'mr-5')}>
                  <Image
                    title="region-card"
                    customClass={clsx('region-card-image')}
                    loading="lazy"
                    altText={regionImage?.dynamicMedia?.altText}
                    renditions={regionImage?.dynamicMedia?.renditions}
                    dynamic={regionImage?.dynamicMedia?.dynamic}
                    defaultImageURL={regionImage?.dynamicMedia?.assetPath}
                  />
                </div>
                <div
                  className={clsx('d-flex', 'flex-column', 'justify-content-center', 'align-items-start', 'text-left')}
                >
                  <Heading
                    variation={Types.headingType.subtitle}
                    titleText={changeRegionNames(region, unitedStatesLabel, australiaPILabel)}
                    fontSize={Types.size.medium}
                    element={Types.tags.h3}
                    customClass={clsx('mb-0', 'region-card-title')}
                  />
                  <Heading
                    variation={Types.headingType.subtitle}
                    titleText={`${formatNumberByLocale(count, currentLocale?.replace('_', '-'))} ${hotelsLabel}`}
                    fontSize={Types.size.medium}
                    element={Types.tags.h3}
                    customClass={clsx('mb-0', 'text-left', 'region-card-subtitle')}
                  />
                </div>
              </div>,
            ]}
            children={
              isLoading ? (
                ''
              ) : count > 0 ? (
                <div className={clsx('region-items-container')}>
                  {localStateORCountryList?.length > 0 && (
                    <div className={clsx('row', isViewportM ? 'mt-4' : 'mt-2')}>{localStateORCountryList}</div>
                  )}
                  <div className={clsx('my-4', 'pt-3', 'region-links-container')}>
                    <Link
                      text={`${
                        name === unitedStatesLabel || name === CARIBBEAN_LABEL
                          ? viewAllHotelsLabel + ' ' + name
                          : viewAllHotelsLabel?.replace('the', '') + ' ' + name
                      }`}
                      linkHref={formatExitURL(
                        code,
                        name,
                        selectedRegion?.selectedRegion?.code,
                        isFiltersApplied,
                        false,
                        true
                      )}
                      linkClassName={clsx('t-subtitle-m', 'region-card-view-all-link', 'custom_click_track')}
                      ariaLabel={`All hotels in ${name}`}
                      target="_self"
                      custom_click_track_value={`${HOTEL_DIRECTORY} | View All Hotels in the ${name} | internal`}
                    ></Link>
                  </div>
                </div>
              ) : pageLevelAlert ? (
                <Messages messageType={'info'} messageHeading={hotelDirectoryTemporarilyUnavailableMsg}></Messages>
              ) : (
                <Messages
                  messageType={'info'}
                  messageHeading={hotelDirectoryNoHotelsMsg}
                  className={clsx('px-2', 'mt-2', 't-font-s', 'no-hotels-error')}
                ></Messages>
              )
            }
            id={code}
            isOpen={code === selectedRegion?.selectedRegion?.code}
          ></Accordion>
        </div>
      </div>
    </StyledRegionCard>
  );
};

export default RegionCard;
