// Imports for external libraries go here.
import React, { FC } from 'react';
import { InfoWindow } from '@react-google-maps/api';

import { MapInfoWindowProps } from './MapInfoWindow.types';
import { StyledMapInfoWindow } from './MapInfoWindow.styles';

// Use named rather than default exports.
export const MapInfoWindow: FC<MapInfoWindowProps> = ({ ...props }) => {
  return (
    <StyledMapInfoWindow>
      <InfoWindow {...props}>{props.children}</InfoWindow>
    </StyledMapInfoWindow>
  );
};
