import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledMerchandisingSmallBanner = styled.div`
  /* clamp (minVal : fallback if scaling value falls below minValue, scaling value, maxValue : fallback if scaling value falls below minValue) */
  /* calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width]))) */
  padding-top: clamp(${toRem(62)}, calc(${toRem(62)} + 24 * ((100vw - ${toRem(365)}) / 211)), ${toRem(86)});
  @media ${baseVariables.mediaQuery.md} {
    padding-top: 0;
  }
  margin: 0 auto;
  .card-container {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    padding: ${toRem(0)} ${toRem(24)};
    border: ${baseVariables.border['borderWidth02']} solid ${baseVariables.color['neutral20']};
    border-radius: ${baseVariables.border['borderRadiusDefault']};
    box-shadow: ${baseVariables.shadows['shadowSubtle01']};
    @media ${baseVariables.mediaQuery.md} {
      align-items: center;
      flex-direction: row;
      padding: ${toRem(32)} ${toRem(44)};
    }
    p {
      margin: 0;
    }
    .image-container {
      @media ${baseVariables.mediaQuery.md} {
        display: flex;
        justify-content: center;
      }
      img {
        width: ${toRem(180)};
      }
    }
    .general-top {
      margin-top: -${toRem(56)};
      @media ${baseVariables.mediaQuery.md} {
        margin-top: 0;
      }
    }
    .merch-img-loader {
      width: clamp(${toRem(170)}, calc(${toRem(170)} + 130 * ((100vw - ${toRem(365)}) / 211)), ${toRem(300)});
      height: ${toRem(98)};
      @media ${baseVariables.mediaQuery.md} {
        width: ${toRem(122)};
        height: ${toRem(68)};
      }
    }

    .flexible-math-card {
      margin-top: ${toRem(16)};
      @media ${baseVariables.mediaQuery.md} {
        margin-top: 0;
      }
    }
    .cobrand-math-content-section {
      margin-top: ${toRem(16)};
      max-width: 100%;
      margin-left: ${toRem(0)};
      padding-left: 0;
      @media ${baseVariables.mediaQuery.md} {
        padding-left: ${toRem(72)};
      }
      @media ${baseVariables.mediaQuery.lg} {
        max-width: ${toRem(240)};
        margin-top: ${toRem(0)};
        margin-left: ${toRem(0)};
        padding-left: 0;
      }
      .total-amount {
        color: ${baseVariables.color.merch20};
      }
    }
    .content-section {
      flex-direction: column;
      @media ${baseVariables.mediaQuery.lg} {
        flex-direction: row;
      }
      .flexible-card {
        display: flex;
        text-align: center;
        flex-direction: column;
        @media ${baseVariables.mediaQuery.md} {
          flex: 1;
          padding-left: ${toRem(72)};
          padding-right: ${toRem(16)};
          text-align: left;
        }
        @media ${baseVariables.mediaQuery.lg} {
          flex: 1;
          padding-left: ${toRem(64)};
          text-align: left;
        }
      }
    }
    .disclaimer-and-cta-section {
      flex-direction: column-reverse;
      @media ${baseVariables.mediaQuery.md} {
        flex-direction: row;
      }
    }
    .evergreen-content-section {
      flex-direction: column;
      margin-top: ${toRem(16)};
      @media ${baseVariables.mediaQuery.md} {
        margin-top: 0;
      }
      @media ${baseVariables.mediaQuery.lg} {
        flex-direction: row;
      }
      .flexible-card {
        display: flex;
        text-align: center;
        flex-direction: column;
        @media ${baseVariables.mediaQuery.md} {
          flex: 1;
          padding-left: ${toRem(72)};
          padding-right: ${toRem(16)};
          text-align: left;
        }
        @media ${baseVariables.mediaQuery.lg} {
          flex: 1;
          padding-left: ${toRem(64)};
          padding-right: ${toRem(192)};
          text-align: left;
        }
      }
    }
    .flexible-card-content-container {
      display: contents;
    }
    .disclaimer-text {
      @media ${baseVariables.mediaQuery.md} {
        margin-left: ${toRem(80)};
      }
    }
    .flexible-click {
      margin-top: ${toRem(24)};
      margin-bottom: ${toRem(24)};
    }
    .disclaimer-and-cta-section .flexible-click {
      margin-bottom: ${toRem(24)};
    }
  }
  .card-math-container {
    @media ${baseVariables.mediaQuery.md} {
      align-items: self-start;
    }
    @media ${baseVariables.mediaQuery.lg} {
      align-items: center;
    }
  }
  .card-container-loader {
    width: 80%;
  }
`;
