import React from 'react';
import clsx from 'clsx';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { TextComponent as TextComponentComp } from '@marriott/mi-ui-library';
import { TextComponentProps } from './TextComponent.types';

export const TextComponentConfig = {
  emptyLabel: 'TextComponent',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/text`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TextComponent = (props: any) => {
  const model = props.model || {};
  const { styleclass, componentId } = model;
  if (model.styleclass) {
    model.styleclass = '';
  }

  return (
    <div data-testid="text-component" data-component-name="o-common-text" id={componentId} className={clsx(styleclass)}>
      <EditableComponent config={TextComponentConfig} {...props}>
        <TextComponentComp {...props} />
      </EditableComponent>
    </div>
  );
};
