// Imports for external libraries go here.
import React, { FC, useState, useRef, useEffect } from 'react';
import clsx from 'clsx';

// separated by a blank line from external imports.
// The closer the import is to the file, the lower it should be in this list.
import { StyledNavigationContainer } from './NavigationContainer.styles';
import { NavigationContainerProps } from './NavigationContainer.types';
import { HeaderLogo, AEMModel, Types } from '@marriott/mi-ui-library';

import { NavigationWrapper, NavigationUtilityLinkWrapper, UtilityNavigationType } from '../../molecules/Navigation';

export const NavigationContainer: FC<NavigationContainerProps> = props => {
  const { UP, DOWN, INDETERMINATE } = Types.ScrollDirection;
  const [scrollDirection, setScrollDirection] = useState<string>(INDETERMINATE);
  const [iswhiteHeader, setIsWhiteHeader] = useState<boolean>(false);
  const [isMegaMenuActive, setIsMegaMenuActive] = useState<boolean>(false);
  const headerContainerRef = useRef<HTMLDivElement | null>(null);
  const { model = {}, cqPath, isAuthorMode } = props;
  const appliedCssClassNames = model?.appliedCssClassNames;
  const { logoUrl, logoImageAltText, logoType } = model?.resourceProperties ?? {};
  const primaryNavigationProps: AEMModel = model?.cqItems ? model.cqItems['primaryNavigation'] : {};
  const isTransparentHeader = model?.resourceProperties?.transparency === 'true';
  const utilityNavigation = (model?.cqItems ? model.cqItems['utilityNavigation'] : {}) as UtilityNavigationType;

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.pageYOffset;
      if (headerContainerRef.current) {
        if (currentScrollPosition > previousScrollPosition.value) {
          setScrollDirection(DOWN);
        } else if (currentScrollPosition > headerContainerRef.current?.clientHeight) {
          setScrollDirection(UP);
        } else {
          setScrollDirection(INDETERMINATE);
        }
      }
      if (currentScrollPosition === 0 && !isMegaMenuActive) {
        setIsWhiteHeader(false);
      }
      previousScrollPosition.value = currentScrollPosition;
    };
    const previousScrollPosition = { value: window.pageYOffset };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMegaMenuActive]);

  useEffect(() => {
    setIsWhiteHeader(isMegaMenuActive);
  }, [isMegaMenuActive]);

  return (
    <StyledNavigationContainer
      id="navcontainer"
      data-component-name="o-common-navigationcontainer"
      ref={headerContainerRef}
      onMouseOver={() => setIsWhiteHeader(true)}
      onFocus={() => {
        setIsWhiteHeader(true);
      }}
      onMouseOut={() => {
        if (!isMegaMenuActive) {
          setIsWhiteHeader(false);
        }
      }}
      className={clsx(appliedCssClassNames, {
        'default-background inverse': scrollDirection !== UP && !iswhiteHeader && isTransparentHeader,
        'header-sticky': scrollDirection === UP,
        'standard t-background-color': iswhiteHeader || scrollDirection === UP || !isTransparentHeader,
      })}
    >
      <header role="banner">
        <div className="row align-items-center mx-0">
          <div
            className={clsx('navigation-container', {
              'container-xl p-0': !appliedCssClassNames?.includes('standard'),
            })}
          >
            <div className="navigation-logo-container">
              <HeaderLogo logoUrl={logoUrl} logoImageAltText={logoImageAltText} logoType={logoType} />
            </div>
            <NavigationWrapper
              isAuthorMode={isAuthorMode}
              cqPath={cqPath}
              model={primaryNavigationProps}
              utilityNavigation={utilityNavigation}
              setIsMegaMenuActive={setIsMegaMenuActive}
            />
            {utilityNavigation?.[':items'] && (
              <NavigationUtilityLinkWrapper
                utilityNavigation={utilityNavigation}
                isRenderedInsideOverlay={false}
                isAuthorMode={isAuthorMode}
                cqPath={cqPath}
                logoUrl={logoUrl}
                logoImageAltText={logoImageAltText}
                logoType={logoType}
              />
            )}
          </div>
        </div>
      </header>
    </StyledNavigationContainer>
  );
};
