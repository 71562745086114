import { FC } from 'react';
import { Icon } from '../../atoms/Icon';
import { Text } from '../../atoms/Text';
import { StyledIconTextBlock } from './IconTextBlock.styles';
import { IconTextProps } from './IconTextBlock.types';
import { tags, size } from '../../utils/enums/enums';

export const IconTextBlock: FC<IconTextProps> = ({
  icon,
  primaryTextContent,
  secondaryTextContent,
  primaryTextFontSize,
  secondaryTextFontSize,
  primaryCustomClass,
  secondaryCustomClass,
}) => {
  return (
    <StyledIconTextBlock className="m-icon-text-block">
      <span className="icon-container ">
        <Icon iconClass={`icon-${icon}`} />
      </span>

      <span className="text-container ">
        <Text
          copyText={primaryTextContent ?? ''}
          customClass={primaryCustomClass}
          fontSize={primaryTextFontSize ?? size.small}
          element={tags.span}
        />
        <Text
          copyText={secondaryTextContent ?? ''}
          customClass={`${secondaryCustomClass} subtext`}
          fontSize={secondaryTextFontSize ?? size.extraSmall}
          element={tags.span}
        />
      </span>
    </StyledIconTextBlock>
  );
};
