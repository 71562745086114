/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import { FC, SyntheticEvent } from 'react';
import clsx from 'clsx';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { DropdownOptionListProps } from './DropdownOptionList.types';
import { StyledDropdownOptionList } from './DropdownOptionList.styles';
import { IconTextBlock } from '../IconTextBlock';

// Use named rather than default exports.
export const DropdownOptionList: FC<DropdownOptionListProps> = ({
  heading,
  listArray,
  nameKey,
  valueKey,
  listIcon,
  clickHandler,
  hiddenFieldsHandler,
  inlineDisplay,
  elementClass,
  descriptionKey,
  lastElementRef,
}) => {
  const clickTrackingLoc = heading;

  const handler = (e: SyntheticEvent, item: Record<string, string | number>): void => {
    e.preventDefault();
    if (item[nameKey]) {
      clickHandler(item[valueKey ? valueKey : nameKey], item['placeId'], item['secondaryDescription'], item);
      if (hiddenFieldsHandler) {
        hiddenFieldsHandler(item);
      }
    }
  };
  const arr = listArray.slice(Math.max(listArray?.length - 5, 0));
  return (
    <StyledDropdownOptionList
      theme={{ itemLength: listArray.length }}
      data-testid="recent-list"
      className={clsx(inlineDisplay ? 'inline-list' : '', elementClass ? elementClass : '')}
    >
      <h3 className={clsx('t-label-xs', 'recent-list-heading')}>{heading}</h3>
      <ul>
        {arr?.map((item: Record<string, string | number>, index) => (
          //added tabindex=0 here and tabindex=1 in anchor tag so that pressing TAB do not revisit the same item twice
          <li
            role="option"
            aria-selected
            onKeyDown={(event: any): void => {
              if (event.key === 'Enter' || event.keyCode === 13) {
                handler(event, item);
              }
            }}
            tabIndex={0}
            className="highlighted-item"
            key={`recent-list-item-${item[nameKey]}`}
            {...(index === arr.length - 1 ? { ref: lastElementRef } : {})}
          >
            <a
              tabIndex={-1}
              href="#"
              onClick={(event: SyntheticEvent): void => handler(event, item)}
              className="custom_click_track"
              {...{ custom_click_track_value: `${clickTrackingLoc}| ${item[nameKey]} |internal` }}
            >
              <IconTextBlock
                icon={listIcon}
                primaryTextContent={`${item[nameKey]}`}
                secondaryTextContent={descriptionKey && item[descriptionKey] ? item[descriptionKey].toString() : ''}
                secondaryCustomClass={'text-secondary'}
              />
            </a>
          </li>
        ))}
      </ul>
    </StyledDropdownOptionList>
  );
};
