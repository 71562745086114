/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { LinkBanner as LinkBannerComp } from '@marriott/mi-ui-library';

export const LinkBannerConfig = {
  emptyLabel: 'LinkBanner',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/linkbanner`,
};

export const LinkBanner = (props: any): JSX.Element => {
  return (
    <div data-testid="link-banner-comp" data-component-name="o-common-linkbanner">
      <EditableComponent config={LinkBannerConfig} {...props} className="container lb__wrapper">
        <LinkBannerComp {...props} />
      </EditableComponent>
    </div>
  );
};
