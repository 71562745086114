import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { CheckBox } from '../../atoms/CheckBox';
import { Heading } from '../../atoms/Heading';
import { headingType, size, tags } from '../../utils/enums/enums';
import { CheckboxControlsListProps, ControlsProps } from './CheckboxControlsList.types';
import { StyledCheckboxControlsList } from './CheckboxControlsList.styles';

const renderControl = (
  control: ControlsProps,
  selectedControls: ControlsProps[],
  handleControlToggle: (control: ControlsProps) => void,
  handleKeyDown: (event: React.KeyboardEvent, control: ControlsProps) => void,
  name: string,
  index: number,
  custom_click_track_value: string,
  clickTrackingLoc: string,
  clickTrackingVal: string,
  colLength: number
) => {
  const controlId = `${name.replace(/ /g, '')}-${index}`;

  return (
    <CheckBox
      key={controlId}
      checkboxId={controlId}
      checkboxName={name}
      checkboxLabel={control?.count ? control?.label + ` (${control?.count})` : control?.label}
      checked={selectedControls.some((item: ControlsProps) => item?.label === control?.label)}
      onChange={() => handleControlToggle(control)}
      onKeyDown={event => handleKeyDown(event, control)}
      setTabIndex={0}
      value={control?.code || control?.label || ''}
      className={`checkbox-controls-list__checkbox col-${colLength || 6} pb-2`}
      inputClassName="checkbox-input custom_click_track"
      {...{
        custom_click_track_value: custom_click_track_value
          ? custom_click_track_value
          : clickTrackingLoc &&
            `${clickTrackingLoc} | ${clickTrackingVal} ${control?.label} ${
              selectedControls.some((item: ControlsProps) => item?.label === control?.label)
                ? 'selection'
                : 'de-selection'
            } | internal`,
      }}
      disabled={control?.disabled}
      iconClassName={control?.iconClass}
    />
  );
};
export const CheckboxControlsList: React.FC<CheckboxControlsListProps> = ({
  title,
  subTitle = '',
  eyebrowText = '',
  controls,
  enableShowMoreToggle = false,
  showMoreCount = 0,
  onChange,
  defaultSelected = [],
  name,
  showMoreLabel = 'Show More',
  showLessLabel = 'Show Less',
  showSeparator = false,
  colLength = 6,
  custom_click_track_value = '',
  clickTrackingLoc = '',
  clickTrackingVal = '',
}) => {
  const [selectedControls, setSelectedControls] = useState<ControlsProps[]>(defaultSelected);
  const [showMore, setShowMore] = useState(true);

  useEffect(() => {
    setSelectedControls(defaultSelected);
  }, [defaultSelected]);

  const handleControlToggle = (control: ControlsProps) => {
    let updatedSelection: ControlsProps[] = [];

    if (selectedControls.some((item: ControlsProps) => item.label === control?.label)) {
      updatedSelection = selectedControls.filter(ctl => ctl.label !== control?.label);
    } else {
      updatedSelection.push(...selectedControls, control);
    }

    setSelectedControls(updatedSelection);
    onChange(updatedSelection);
  };

  const handleKeyDown = (event: React.KeyboardEvent, control: ControlsProps) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleControlToggle(control);
    }
  };

  const renderedControls = !enableShowMoreToggle || !showMore ? controls : controls.slice(0, showMoreCount);
  const btnLabel = !showMore ? showLessLabel : showMoreLabel;

  return (
    <StyledCheckboxControlsList className={`col-12 checkbox-controls-list`}>
      <fieldset>
        {title && (
          <div className="my-4 mt-md-5 mb-md-4 mt-lg-5 mb-lg-4 mt-xl-5 mb-xl-4">
            <legend>
              <Heading
                variation={headingType.subtitle}
                titleText={title}
                fontSize={size.large}
                element={tags.h4}
                customClass={'checkbox-controls-list__title'}
              ></Heading>
            </legend>
          </div>
        )}
        {subTitle && (
          <Heading
            variation={headingType.subtitle}
            titleText={subTitle}
            fontSize={size.large}
            element={tags.h5}
            customClass={'pt-0 pb-3'}
          ></Heading>
        )}
        {eyebrowText && <p className="t-label-xs filter-sub-heading mb-0 pb-3 pt-0">{eyebrowText}</p>}
        <div className="row">
          {renderedControls.map((control, index) =>
            renderControl(
              control,
              selectedControls,
              handleControlToggle,
              handleKeyDown,
              name,
              index,
              custom_click_track_value,
              clickTrackingLoc,
              clickTrackingVal,
              colLength
            )
          )}
        </div>
        {enableShowMoreToggle && controls.length > showMoreCount && (
          <button
            className="show-more-btn t-font-s p-0 d-flex custom_click_track"
            onClick={() => setShowMore(!showMore)}
            custom_click_track_value={
              clickTrackingLoc && `${clickTrackingLoc} | ${showMore ? showLessLabel : showMoreLabel} | internal`
            }
          >
            {btnLabel}{' '}
            <span
              className={clsx('show-more-icon', { 'icon-dropdown-down': showMore, 'icon-dropdown-up': !showMore })}
            ></span>
          </button>
        )}
        {showSeparator && <hr className="filter-separator mt-4 mt-md-5 mt-lg-5 mt-xl-5" />}
      </fieldset>
    </StyledCheckboxControlsList>
  );
};
