/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { FC, SyntheticEvent } from 'react';
import { IconTextBlock } from '@marriott/mi-ui-library';

import { StyledCurrentLocation } from './CurrentLocation.styles';

export interface CurrentLocationViewProps {
  currentLocationLabel: string;
  clickTrackingValue: string;
  clickHandler: (e: SyntheticEvent) => void;
  iconTextBlockCustomClass?: string;
}

const CurrentLocationView: FC<CurrentLocationViewProps> = ({
  currentLocationLabel,
  clickTrackingValue,
  clickHandler,
  iconTextBlockCustomClass,
}) => {
  return (
    <StyledCurrentLocation>
      <div
        onClick={(event: SyntheticEvent): void => clickHandler(event)}
        onKeyDown={(event: any): void => {
          if (event.key === 'Enter' || event.keyCode === 13) {
            clickHandler(event);
          }
        }}
        className="t-subtitle-m custom_click_track highlighted-item "
        role="button"
        tabIndex={0}
        {...{ custom_click_track_value: `${currentLocationLabel}| ${clickTrackingValue} |internal` }}
      >
        <IconTextBlock
          icon="nearby"
          primaryTextContent={currentLocationLabel}
          primaryCustomClass={iconTextBlockCustomClass}
        />
      </div>
    </StyledCurrentLocation>
  );
};

export default CurrentLocationView;
