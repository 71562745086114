import React from 'react';
import clsx from 'clsx';
import { ImageProps, RenditionProps } from './Image.types';

export const Image = (props: ImageProps) => {
  const { title, defaultImageURL, customClass, renditions, altText = '', loading = 'lazy' } = props;

  return (
    <picture>
      {renditions?.map((rendition: RenditionProps, index: number) => {
        const { renditionPath, mediaValue, mediaQuery } = rendition;
        const media = `(${mediaQuery}: ${mediaValue})`;
        return (
          <source
            key={index}
            media={media}
            srcSet={renditionPath}
            data-srcset={renditionPath}
            data-testid={'source-img-' + index}
          />
        );
      })}

      <img
        src={defaultImageURL}
        className={clsx('image', loading, customClass)}
        alt={altText}
        title={title}
        data-src={defaultImageURL}
        loading={loading}
      />
    </picture>
  );
};
