import React from 'react';
import { StyledSkeletonLoaderContainer } from './SkeletonLoader.styles';
import clsx from 'clsx';

export const SkeletonLoader: React.FC = () => {
  return (
    <StyledSkeletonLoaderContainer className="sl-container">
      <img
        src="https://cache.marriott.com/content/dam/marriott-digital/cy/us-canada/hws/h/hpnnb/en_us/logo/internal/assets/cy-hpnnb-grey-image-placeholder-34740.png?imbypass=on"
        alt="loading"
        className={clsx('image-place-loader')}
      ></img>
    </StyledSkeletonLoaderContainer>
  );
};
