// Styles for RichText go here.
import styled from 'styled-components';
import { baseVariables } from '../../styles';

export const StyledRichText = styled.div`
  .custom-link {
    display: block;
    @media ${baseVariables.mediaQuery.md} {
      display: inline;
    }
  }
  .line-separator {
    display: none;
    @media ${baseVariables.mediaQuery.md} {
      display: inline;
    }
  }
`;
