// Imports for external libraries go here.
import React, { FC, useState, useEffect } from 'react';
import clsx from 'clsx';
import { StyledSignInButton, AccountDrawer } from './SignInDrawer.styles';
import { AccountPanel } from './AccountPanel';
import { SignInDrawerProps } from './SignInDrawer.types';
import { AuthStateEnum } from '@marriott/shared/mi-helper-utils';
import { canUseDOM, HeaderLogoProps } from '@marriott/mi-ui-library';
import { MemberPanelProps } from './MemberPanel';
import { SignInFormProps } from './SignInForm';
import { useGlobalStore, useClientEnvVarsStore } from '@marriott/mi-store-utils';
import { getClickTrackValue } from '../../utils/TrackingPropertyUtils';
import { DEFAULT_TRACKING_VALUES } from '../../utils/constants';

// Use named rather than default exports.
export const SignInDrawer: FC<SignInDrawerProps> = props => {
  const { ATTEMPTED_SIGNIN } = AuthStateEnum;
  const envVarsObject = useClientEnvVarsStore(state => state.envVarsObject);
  const { GET_USER_DETAILS } = envVarsObject;
  const { logoUrl, logoImageAltText, logoType, restProps, renderUtilityLink } = props;
  const {
    joinNowLabel,
    becomeMemberLabel,
    becomeMemberUrl,
    signInLabel,
    memberNumberLabel,
    memberNumberPlaceHolder,
    loginPasswordLabel,
    loginPasswordPlaceHolder,
    remeberMeLabel,
    signInButtonLabel,
    signInButtonUrl,
    joinNowSubHeading,
    forgotPasswordLinkLabel,
    forgotPasswordLinkPath,
    activeAccountLinkUrl,
    activateAccountLinkLabel,
    clearRememberedAccountLabel,
    clearRememberedAccountUrl,
    welcomeText,
    signInSubHeading,
    brandName,
    pointsLabel,
    buyPointsLinkLabel,
    buyPointsLinkPath,
    logoutLinkLabel,
    logoutLinkPath,
    warningMessage,
    credentialsWarningMessage,
    lockoutWarningMessage,
    lockedWarningMessage,
    auditWarningMessage,
  } = restProps.resourceProperties ?? {};

  const [isOpen, setIsOpen] = useState(false);
  // TODO: When Signed In story is implemented we should be using Zustand to access data from store.
  const togglePanel = () => {
    setIsOpen(prevState => !prevState);
  };
  const userProfileData = useGlobalStore(state => state.userProfileData);
  const isRememberedUser = useGlobalStore(state => state.isRememberedUser);
  const isSignedInUser = useGlobalStore(state => state.isSignedInUser);
  const sessionToken = canUseDOM ? window?.dataLayer?.['sessionId'] : '';
  const localeKey = canUseDOM ? window?.dataLayer?.['device_language_preferred'] : '';
  const payload = {
    sessionToken: sessionToken,
    context: {
      context: {
        localeKey: localeKey,
      },
    },
  };

  const getUserDetailsApiCall = async () => {
    try {
      const response = await fetch(GET_USER_DETAILS, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        ...payload,
      });
      const getUserDetailsResponse = await response.json();
      useGlobalStore.setState({ userProfileData: getUserDetailsResponse });
    } catch (error) {
      // TODO: Use logger - getting error while accessing logger.
      console.log('Error while retriveing user data', error);
    }
  };
  useEffect(() => {
    getUserDetailsApiCall();
    if (isSignedInUser && localStorage?.getItem(ATTEMPTED_SIGNIN) === 'true') {
      setIsOpen(true);
    }
    localStorage.removeItem(ATTEMPTED_SIGNIN);

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const memberName =
    isSignedInUser || isRememberedUser
      ? userProfileData?.headerSubtext?.consumerName
      : restProps?.resourceProperties?.['cq:panelTitle'];
  const signInButtonClass = `nav---account${isSignedInUser || isRememberedUser ? '-alt-active' : ''}`;

  const signInErrorMessages = {
    warningMessage,
    credentialsWarningMessage,
    lockoutWarningMessage,
    lockedWarningMessage,
    auditWarningMessage,
    pwrdErrorMessage: restProps.pwrdErrorMessage,
    emailErrorLabel: restProps.emailErrorLabel,
    errorForUserName: restProps.errorForUserName ?? '',
    errorForPassword: restProps.errorForPassword ?? '',
  };

  const signInFormProps: SignInFormProps = {
    signInLabel,
    memberNumberLabel,
    memberNumberPlaceHolder,
    loginPasswordLabel,
    loginPasswordPlaceHolder,
    remeberMeLabel,
    signInButtonLabel,
    signInButtonUrl,
    forgotPasswordLinkLabel,
    forgotPasswordLinkPath,
    activateAccountLinkLabel,
    activeAccountLinkUrl,
    clearRememberedAccountLabel,
    clearRememberedAccountUrl,
    welcomeText,
    signInSubHeading,
    brandName,
    signInErrorMessages,
  };
  const memberPanelProps: MemberPanelProps = {
    joinNowLabel,
    joinNowSubHeading,
    becomeMemberLabel,
    becomeMemberUrl,
    pointsLabel,
    buyPointsLinkLabel,
    buyPointsLinkPath,
    memberPanelCardLinks: restProps?.listItems,
    logoutLinkLabel,
    logoutLinkPath,
    memberItems: restProps?.memberItems,
  };
  const headerLogoProps: HeaderLogoProps = {
    logoUrl,
    logoImageAltText,
    logoType,
    customClassName: 'panelLogo',
    isClickable: false,
  };

  const clickTrackValue = getClickTrackValue({
    trackingProperties: {
      trackingContentPosition: restProps?.trackingProperties?.trackingContentPosition,
    },
    url: restProps['cq:panelUrl'],
    fallbacks: {
      description: restProps['cq:panelTitle'],
      position: DEFAULT_TRACKING_VALUES.GLOBAL_NAV,
    },
  });

  return (
    <>
      <StyledSignInButton
        className={clsx('t-label-inverse-xs custom_click_track', {
          'rememberedBtn p-0 d-flex align-items-center': isSignedInUser || isRememberedUser,
        })}
        onClick={togglePanel}
        data-custom_click_track_value={clickTrackValue}
      >
        {renderUtilityLink(restProps, signInButtonClass, memberName, 'signin')}
        {(isSignedInUser || isRememberedUser) && <span className="icon-arrow-right d-none d-lg-inline-block"></span>}
      </StyledSignInButton>
      {isOpen && (
        <AccountDrawer isOpen={isOpen} className="standard t-background-color">
          <AccountPanel
            togglePanel={togglePanel}
            headerLogoProps={headerLogoProps}
            memberPanelProps={memberPanelProps}
            signInFormProps={signInFormProps}
          />
        </AccountDrawer>
      )}
    </>
  );
};
