import { FC } from 'react';
import { SwitchProps } from './Switch.types';
import { StyledSwitchDiv } from './Switch.styles';

export const Switch: FC<SwitchProps> = ({
  className,
  toggleId,
  toggleName,
  selected,
  children,
  value,
  inputClassName,
  onChange,
  ...props
}) => {
  return (
    <StyledSwitchDiv className={className}>
      <label className="toggle-switch-wrapper d-flex align-items-center" htmlFor={toggleId}>
        <span className="toggle-switch mr-2">
          <input
            type="checkbox"
            id={toggleId}
            name={toggleName}
            checked={selected}
            onChange={onChange}
            value={value}
            {...props}
            className={inputClassName}
          />
          <span className="toggle-slider"></span>
        </span>
        {children}
      </label>
    </StyledSwitchDiv>
  );
};

export default Switch;
