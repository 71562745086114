/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, Suspense, lazy, useEffect, useState } from 'react';
import { ResponsiveGrid } from '@adobe/aem-react-editable-components';
import Glide from '@glidejs/glide';
import clsx from 'clsx';
import { CardCarouselProps } from './CardCarouselContainer.types';
import { StyledCardCarousel } from './CardCarouselContainer.styles';
import { Button } from '../../atoms/Button';
import { Eyebrow } from '../../atoms/Eyebrow';
import { Heading } from '../../atoms/Heading';
import { Text } from '../../atoms/Text';
import { headingType, size, tags } from '../../utils/enums/enums';
import { AEMReactCompMap } from '../../utils/AEMReactCompMapUtil';

export const CardCarouselContainer: FC<CardCarouselProps> = (props: CardCarouselProps) => {
  const {
    headerText,
    subHeaderText,
    ctaLabel,
    ctaLink,
    eyebrow,
    ctaType,
    cardCount,
    openInaNewTab,
    totalNumberOfCards,
    trackingProperties,
    styleclass,
    cqItems,
    componentId,
    pagePath,
    itemPath,
    isAuthorMode,
    variation,
    enableTabletBreakpoint,
  } = props;

  let { noOfCards, noOfCardsTablet } = props;
  const isCombo = (): boolean => {
    return variation === 'combo';
  };

  if (isCombo()) {
    noOfCards = 2;
    noOfCardsTablet = 2;
  }

  const [cardsPerView, setCardsPerView] = useState(noOfCards);

  const mapper = AEMReactCompMap(props?.allowedComponents);
  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  const handleResize = () => {
    const xs = window.matchMedia('(max-width: 575px)');
    const sm = window.matchMedia('(min-width:576px) and (max-width: 767px)');
    const md = window.matchMedia('(min-width: 768px) and (max-width: 1199px)');
    const xl = window.matchMedia('(min-width: 1200px)');
    if (enableTabletBreakpoint) {
      if (xl?.matches) {
        setCardsPerView(noOfCards);
      } else if (md?.matches) {
        setCardsPerView(noOfCardsTablet);
      } else if (sm?.matches) {
        setCardsPerView(noOfCardsTablet);
      } else if (xs?.matches) {
        setCardsPerView(1);
      }
    } else {
      if (xs?.matches) {
        setCardsPerView(1);
      } else if (sm?.matches) {
        setCardsPerView(1);
      } else if (md?.matches) {
        setCardsPerView(noOfCardsTablet);
      } else if (xl?.matches) {
        setCardsPerView(noOfCards);
      }
    }
  };

  useEffect(() => {
    const pageWcmMode = document.getElementsByTagName('html')[0];
    const updateStyleComponent = document.getElementById(`${componentId}__slides`);
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.attributeName === 'class') {
          const currWcmMode = pageWcmMode?.getAttribute('class');
          if (currWcmMode?.includes('Edit')) {
            updateStyleComponent?.classList.add('glide__slides_authoring');
          } else if (currWcmMode?.includes('Preview')) {
            updateStyleComponent?.classList.remove('glide__slides_authoring');
          }
        }
      });
    });
    observer.observe(pageWcmMode, { attributes: true });
    handleResize();

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const carouselRootSelector = `#${componentId}`;
    if (document?.querySelector(carouselRootSelector)) {
      try {
        new Glide(carouselRootSelector, {
          type: 'slider',
          bound: true,
          perView: noOfCards,
          startAt: 0,
          rewind: false,
          gap: 16,
          dragThreshold: false,
          breakpoints: enableTabletBreakpoint
            ? {
                1199: {
                  perView: noOfCardsTablet,
                },
                575: {
                  perView: 1,
                },
              }
            : {
                1199: {
                  perView: noOfCardsTablet,
                },
                767: {
                  perView: 1,
                },
              },
        }).mount({ ArrowDisabler });

        const addArrowEventListener = (arrowId: string, clickTrack: string) => {
          const arrow = document?.querySelector(`#${arrowId}`);
          if (arrow) {
            arrow.addEventListener('touchstart', (e: any) => {
              document.getElementById(clickTrack)?.click();
              e.preventDefault();
              e.stopImmediatePropagation();
            });
            arrow.addEventListener('click', (e: any) => {
              document.getElementById(clickTrack)?.click();
              e.stopImmediatePropagation();
            });
          }
        };

        addArrowEventListener(`${componentId}_left_arrow`, 'left-arrow-click-track');
        addArrowEventListener(`${componentId}_right_arrow`, 'right-arrow-click-track');
      } catch (error) {
        // log.error(`Error in initializing Glide for ${componentId}`, error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorMode, componentId, cardsPerView]);

  const buttonCallback = () => {
    window.open(ctaLink, openInaNewTab ? '_blank' : '_self');
  };

  const ArrowDisabler = function (Glide: any, Components: any) {
    return {
      mount() {
        // Only in effect when rewinding is disabled
        if (Glide.settings.rewind) {
          return;
        }
        Glide.on(['mount.after', 'run'], () => {
          // Filter out arrows_control
          for (const controlItem of Components.Controls.items) {
            if (controlItem.className !== 'glide__arrows') {
              continue;
            }

            // Set left arrow state
            const left = controlItem?.querySelector('.glide__arrow--left');
            if (left) {
              if (Glide.index === 0) {
                left.classList.add('.is-disabled'); // Disable on first slide
              } else {
                left.classList.remove('.is-disabled'); // Enable on other slides
              }
            }

            // Set right arrow state
            const right = controlItem?.querySelector('.glide__arrow--right');
            if (right) {
              if (Glide.index === Components.Sizes.length - Glide.settings.perView) {
                right.classList.add('.is-disabled'); // Disable on last slide
              } else {
                right.classList.remove('.is-disabled'); // Disable on other slides
              }
            }
          }
        });
      },
    };
  };

  const customAttributes = trackingProperties?.enableScrollingBehavior ? { 'data-section-tracking': componentId } : {};
  const isLink = ctaType === 'tertiaryLink';

  const header = () =>
    headerText && (
      <Heading
        customClass={clsx({ 'mt-2 mb-3': isCombo() }, 'm-ellipsis-2lines')}
        titleText={headerText}
        variation={headingType.title}
        fontSize={isCombo() ? size.medium : size.small}
      />
    );
  const subHeader = () =>
    subHeaderText && (
      <Text
        copyText={subHeaderText}
        fontSize={size.medium}
        element={tags.span}
        customClass={clsx({ 'mb-3': isCombo() || eyebrow }, isCombo() ? 'm-ellipsis-3lines' : 'm-ellipsis-2lines')}
      />
    );

  const cta = () => {
    return (
      ctaLabel && (
        <div className={clsx(!eyebrow && !subHeaderText ? 'carousal_header--cta mb-2' : 'carousal_header--cta')}>
          <Button
            href={ctaLink}
            isLink={isLink}
            target={openInaNewTab ? '_blank' : '_self'}
            callback={!isLink ? buttonCallback : undefined}
            className={[
              '',
              ctaType === 'primaryButton' ? 'm-button-primary' : '',
              ctaType === 'secondaryButton' ? 'm-button-secondary' : '',
              isLink ? 'm-link-tertiary-button' : '',
              openInaNewTab
                ? ctaType === 'primaryButton' || ctaType === 'secondaryButton'
                  ? 'm-button-external'
                  : 'm-link-tertiary-button-external'
                : '',
            ]}
            custom_click_track_value={`${trackingProperties?.cardLocation}|${trackingProperties?.description}|${
              openInaNewTab ? 'external' : 'internal'
            }`}
            buttonCopy={ctaLabel}
            isTextBeforeChildren={false}
          />
        </div>
      )
    );
  };

  const eyebrowContent = () => eyebrow && <Eyebrow text={eyebrow} />;

  const carouselHeader = () => {
    return (
      <div className={clsx('carousal_header')}>
        <div className={clsx({ 'pb-2': eyebrow })}>
          {eyebrowContent()}
          {header()}
          {subHeader()}
        </div>
        {cta()}
      </div>
    );
  };

  const carouselComboHeader = () => {
    return (
      <div className={clsx({ 'pr-3': isCombo() }, 'carousal_header')}>
        <div>
          {eyebrowContent()}
          {header()}
          {subHeader()}
        </div>
        {cta()}
      </div>
    );
  };

  // This function is used to render the component in authoring mode authorCardWrapper
  const authorCardWrapper = (index: number, innerCompStyleClass: string | undefined) => {
    return (
      <ResponsiveGrid
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pagePath={pagePath}
        itemPath={`${itemPath}/${totalNumberOfCards[index]}`}
        columnCount="12"
        gridClassNames={''}
        customClassName={clsx(innerCompStyleClass)}
        config={{
          isEmpty: () => true,
          resourceType: 'mi-aem-common-spa/components/container',
        }}
      />
    );
  };

  // This function is used to render the component in end-user mode
  const publishCardWrapper = (cardName: string, jsonData: any) => {
    // eslint-disable-next-line no-prototype-builtins
    if (jsonData?.hasOwnProperty(cardName)) {
      const card = jsonData[cardName];
      const cardItems = card[':items'];
      for (const itemKey in cardItems) {
        if (Object.prototype.hasOwnProperty.call(cardItems, itemKey)) {
          const item = cardItems[itemKey];
          const itemType = item[':type']?.split('/').pop();
          if (Object.prototype.hasOwnProperty.call(mapper, itemType)) {
            const innerComp = mapper[itemType];
            const Component = lazy(() =>
              import(`../${innerComp}/index`).then(module => ({
                default: module[`${innerComp}`],
              }))
            );

            return (
              <li key={itemKey}>
                <Suspense fallback={<></>}>
                  <Component {...item} />
                </Suspense>
              </li>
            );
          }
          return null;
        }
      }
    }
    return null;
  };

  return (
    <StyledCardCarousel className={clsx(`col-12 py-3 px-0 ${styleclass}`)} {...customAttributes} variation={variation}>
      {isCombo() ? carouselComboHeader() : carouselHeader()}
      <div id={componentId} className={clsx(`glide ${isCombo() ? 'col-xl-8 p-0' : ''}`)}>
        <div className={clsx(!subHeaderText && !eyebrow ? 'glide__track__pt3' : 'glide__track')} data-glide-el="track">
          <ul className={clsx('glide__slides', 'pb-3')} id={`${componentId}__slides`}>
            {isAuthorMode &&
              Array.from({ length: totalNumberOfCards.length }, (_, i) => authorCardWrapper(i, styleclass))}
            {!isAuthorMode && totalNumberOfCards.map((cardName: string) => publishCardWrapper(cardName, cqItems))}
          </ul>
        </div>
        {cardCount !== cardsPerView && (
          <div className="center-align">
            <div className="carouselControlType1">
              <div className="glide__arrows" data-glide-el="controls">
                <button
                  className={clsx('left-arrow', 'glide__arrow--left')}
                  id={`${componentId}_left_arrow`}
                  data-glide-dir="<"
                  aria-label="left-arrow"
                >
                  <span className="icon-arrow-left" id={`${componentId}_left_arrow`} data-id="left"></span>
                </button>
              </div>
              <input
                type="hidden"
                id="left-arrow-click-track"
                className={clsx(`${trackingProperties?.clickTrack ? 'custom_click_track' : ''}`)}
                data-custom_click_track_value={`${trackingProperties?.cardLocation}|${trackingProperties?.leftArrowDesc}|internal`}
              />
              <div data-glide-el="controls[nav]">
                {Array.from({ length: cardCount - (cardsPerView - 1) }, (_, i) => (
                  <button
                    className=""
                    data-glide-dir={i}
                    aria-label={`control-button-${i}`}
                    key={cardsPerView + i}
                  ></button>
                ))}
              </div>
              <div className="glide__arrows" data-glide-el="controls">
                <button
                  className={clsx('right-arrow', 'glide__arrow--right')}
                  id={`${componentId}_right_arrow`}
                  data-glide-dir=">"
                  aria-label="right-arrow"
                >
                  <span className="icon-arrow-right" data-id="right"></span>
                </button>
              </div>
              <input
                type="hidden"
                id="right-arrow-click-track"
                className={clsx(`${trackingProperties?.clickTrack ? 'custom_click_track' : ''}`)}
                data-custom_click_track_value={`${trackingProperties?.cardLocation}|${trackingProperties?.rightArrowDesc}|internal`}
              />
            </div>
          </div>
        )}
      </div>
    </StyledCardCarousel>
  );
};
