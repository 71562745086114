/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { CheckBox } from '@marriott/mi-ui-library';
import { useSearchFormStore } from '../../modules/store/searchFormStore';
import { useStaticDataContext } from '../../modules/context';
import { StyledUsePoints } from './UsePoints.styles';
import { FOCUS_INTERACTIONS, SPECIAL_RATES, USE_POINTS } from '../../modules/store/store.constants';
import clsx from 'clsx';

declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface HTMLAttributes<T> {
    custom_click_track_value?: string;
  }
}
export const UsePoints = () => {
  const setSearchFormState = useSearchFormStore((state: any) => state.setSearchFormState);
  const checkboxValue = useSearchFormStore((state: any) => state.usePoints.rewardsRedemption);
  // const [checkboxValue, setCheckboxValue] = useState(g);
  const { usePointsCertificates, disableUsePointCertificates } = useStaticDataContext();
  const clickTrackingLoc = 'Search Form';
  const { isTabbedSearchForm } = useStaticDataContext();

  // method to handle check/uncheck of usepoints checkbox
  const handleCheck = (event: any): void => {
    if (event.key === 'Enter' || event.type === 'change' || event.keyCode === 13) {
      // when use points is set to some value then special rates will set to default
      //on search page capture user interactions
      setSearchFormState([SPECIAL_RATES, USE_POINTS, FOCUS_INTERACTIONS], {
        [SPECIAL_RATES]: {
          clusterCode: '',
          specialRateCode: 'none',
          corporateCode: '',
        },
        [USE_POINTS]: {
          rewardsRedemption: !checkboxValue,
        },
        [FOCUS_INTERACTIONS]: { isUserInteractWithComp: true },
      });
      // setCheckboxValue(!g)
    }
  };

  if (disableUsePointCertificates) {
    return null;
  }

  return (
    <StyledUsePoints>
      <CheckBox
        inputClassName="custom_click_track"
        className={clsx(isTabbedSearchForm ? 'adv-searchform' : 'checkbox-label t-font-weight-m t-font-xs')}
        checkboxId="usepoints-checkbox"
        checkboxName={usePointsCertificates}
        onChange={(e: any): void => handleCheck(e)}
        onKeyDown={(e: any): void => handleCheck(e)}
        checked={checkboxValue}
        value={String(checkboxValue)}
        custom_click_track_value={`${clickTrackingLoc}| Points/Awards checkbox |internal`}
        children={
          <div className={clsx(isTabbedSearchForm ? 't-font-s' : 't-font-weight-m')}>{usePointsCertificates}</div>
        }
      />
    </StyledUsePoints>
  );
};
