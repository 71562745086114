import { EditableComponent } from '@adobe/aem-react-editable-components';
import { IconBlock as IconBlockComp, IconBlockProps } from '@marriott/mi-ui-library';
import { IconBlockStyled } from './IconBlock.styles';

export const IconBlockConfig = {
  emptyLabel: 'IconBlock',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/iconblock`,
};

export const IconBlock = (props: IconBlockProps) => (
  <IconBlockStyled
    className="d-flex p-0 col-12 standard"
    data-testid="icon-block-comp"
    data-component-name="o-common-iconblock"
  >
    <EditableComponent config={IconBlockConfig} {...props}>
      <IconBlockComp {...props} />
    </EditableComponent>
  </IconBlockStyled>
);
