import React, { MouseEvent } from 'react';
import clsx from 'clsx';
import { HeaderLogoProps } from './HeaderLogo.types';
import { StyledHeaderLogo } from './HeaderLogo.styles';

export const HeaderLogo = (props: HeaderLogoProps) => {
  const { logoType, logoUrl = '', logoImageAltText = '', customClassName = '', isClickable = true } = props;
  const handleClick = (event: MouseEvent) => {
    if (!isClickable) {
      event.preventDefault();
    }
  };
  return (
    // TODO: See if there is a potential to reuse common icon component
    <StyledHeaderLogo
      className={clsx('m-header__logo-wrap mr-0', customClassName)}
      onClick={event => handleClick(event)}
    >
      <a href={logoUrl} className={clsx('m-header__logo-icon', logoType || 't-program-logo-alt-color')}>
        <span className="sr-only">{logoImageAltText}</span>
      </a>
    </StyledHeaderLogo>
  );
};
