/* eslint-disable @typescript-eslint/no-explicit-any */
import Cookies from 'js-cookie';
import { logger } from '../logger';
import { DataLayerAttributes } from './index.types';
import { getDataLayer } from './index';
import { COOKIE_KEYS } from '../../lib/constants';
import {
  PersistentDatalayerAttributes,
  SessionData,
  DatalayerValueGenerators as ValueGenerators,
} from '../session/types';
import {
  getAkamaiLocationAttrs,
  getCurrentDateTime,
  getCurrentLocale,
  getDatalayerAttrList,
  getEnvServerId,
  getEnvSiteId,
  propertyResolver,
} from './datalayerHelpers';

export const getDatalayerResponse = async (req: any) => {
  global.loggerInstance = logger({
    sessionID: req?.cookies?.sessionID,
    requestID: req.headers['x-request-id'],
  }) as CallableFunction;
  const { log } = global.loggerInstance('getDatalayerResponse');
  const requestId = req.headers['x-request-id'];
  const currentLocale = req.headers['accept-language'];
  const locale = currentLocale?.split(',')[0]?.replace('-', '_');
  log.debug(`getDataLayer request headers Accept-Language: ${req.headers['accept-language']}`);
  log.debug(
    `getDataLayer request headers requestId: ${req.headers['x-request-id']} and host: ${req.headers['x-host']}`
  );
  log.debug(`getDataLayer resolvedUrl passed ${requestId?.split('.mi')[0]}`);
  log.debug(`getDataLayer locale passed ${locale}`);
  const datalayerResp = await getDataLayer(req, requestId?.split('.mi')[0], locale);

  const dataLayer = JSON.stringify(datalayerResp?.data[0] || {});
  const mvpOffers =
    datalayerResp?.mvpOffersData && datalayerResp?.mvpOffersData !== 'null' ? datalayerResp?.mvpOffersData : '{}';

  return { dataLayer, mvpOffers };
};

export function normalizeDataAttributes(attributes?: DataLayerAttributes[]) {
  return attributes?.reduce((acc: Record<string, string>, attr: DataLayerAttributes) => {
    if (attr.value && attr.value !== 'undefined' && attr.value !== null) {
      acc[attr.key as string] = attr.value as string;
    }
    return acc;
  }, {});
}

const datalayerValueGenerator: ValueGenerators = {
  browser_akamai_loc_country: ({ akamaiLocationAttrs }) => akamaiLocationAttrs?.country_code,
  browser_akamai_loc_lat: ({ akamaiLocationAttrs }) => akamaiLocationAttrs?.lat,
  browser_akamai_loc_long: ({ akamaiLocationAttrs }) => akamaiLocationAttrs?.long,
  browser_akamai_loc_city: ({ akamaiLocationAttrs }) => akamaiLocationAttrs?.city,
  browser_akamai_loc_state: ({ akamaiLocationAttrs }) => akamaiLocationAttrs?.region_code,
  cookie_mi_site: ({ sessionData }) => propertyResolver(sessionData?.data?.xHost),
  cookie_personalization_guid: () => propertyResolver(Cookies.get(COOKIE_KEYS.personalization)),
  device_language_preferred: () => getCurrentLocale(),
  env_date_time: () => getCurrentDateTime(),
  env_server_id: () => getEnvServerId(),
  env_site_id: () => getEnvSiteId(),
  env_language: () => '', // check its value??
  page_requested_uri: () => propertyResolver(window.location.pathname),
  page_url_query_string: () => propertyResolver(window.location.search),
  request_id: () => '', // read X-Request-Id from session data
  sessionId: ({ sessionData }) => propertyResolver(sessionData?.data?.sessionToken),
  site_id: () => getEnvSiteId(),
};

// Reading the datalayer attributes from master list and populating them from value generator function
// if some key is present is master list but in generator function, picking up default value from master list
const getFilteredDataLayerValues = (
  datalayerAttrList: PersistentDatalayerAttributes,
  valueGenerator: ValueGenerators,
  sessionData: SessionData
) => {
  const akamaiLocationAttrs = getAkamaiLocationAttrs(sessionData?.data?.akamaiHeader);
  return Object.fromEntries(
    Object.entries(datalayerAttrList).map(([key, defaultValue]) => [
      key,
      valueGenerator[key] ? valueGenerator[key]({ sessionData, akamaiLocationAttrs }) : defaultValue,
    ])
  );
};

const addMissingDataLayerElements = (
  newDataLayer: { [k: string]: string | number | boolean | undefined },
  options: DataLayerConstructionOptions
) => {
  if (!options.fillMissingElements) {
    return newDataLayer;
  }
  const oldDataLayer = JSON.parse(window.sessionStorage.getItem('oldDataLayer') || '{}') as {
    [k: string]: string | number | boolean | undefined;
  };
  Object.keys(oldDataLayer).forEach(key => {
    if (!newDataLayer[key] && ['undefined', 'string'].indexOf(typeof newDataLayer[key]) !== -1) {
      newDataLayer[key] = oldDataLayer[key];
    }
  });
  return newDataLayer;
};

export type DataLayerConstructionOptions = {
  includeStaticElements: boolean;
  fillMissingElements: boolean;
};

export const getDatalayerObject = (
  sessionData: SessionData,
  options: DataLayerConstructionOptions = {
    includeStaticElements: true,
    fillMissingElements: true,
  }
) =>
  addMissingDataLayerElements(
    getFilteredDataLayerValues(getDatalayerAttrList(), datalayerValueGenerator, sessionData),
    options
  );
