/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { StateCreator } from 'zustand';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { createAppStore } from '@marriott/mi-store-utils';

import { searchFormHelperFunction } from '../utils/searchFormHelper';
import {
  LEISURE_REGION_LIST,
  SELECTED_REGION,
  SHOW_PAGE_LEVEL_ALERT,
  SHOW_REGION_CARD_ERROR,
  STATE_COUNTRY_LIST,
  TOTAL_NO_OF_HOTELS_WORLDWIDE,
} from '../constants/HotelDirectory.constants';

interface HotelDirectoryState {
  stateORCountryList: any;
  leisureRegionList: any;
  selectedRegion: any;
  showPageLevelAlert: any;
  showRegionCardError: any;
  totalNoOfHotelsWorldwide: any;
  setHotelDirectoryState: (fieldNamesArray: Array<string>, fieldsData: any, reset?: boolean) => any;
}
const initialState: HotelDirectoryState = {
  [LEISURE_REGION_LIST]: {
    [LEISURE_REGION_LIST]: [{ code: 'united-states', label: 'United States', count: 0, latitude: 0, longitude: 0 }],
  },
  [STATE_COUNTRY_LIST]: { [STATE_COUNTRY_LIST]: [] },
  [SELECTED_REGION]: {
    [SELECTED_REGION]: { code: 'united-states', label: 'United States', count: 0, latitude: 0, longitude: 0 },
  },
  [SHOW_PAGE_LEVEL_ALERT]: {
    [SHOW_PAGE_LEVEL_ALERT]: false,
  },
  [SHOW_REGION_CARD_ERROR]: {
    [SHOW_REGION_CARD_ERROR]: false,
  },
  [TOTAL_NO_OF_HOTELS_WORLDWIDE]: {
    [TOTAL_NO_OF_HOTELS_WORLDWIDE]: 0,
  },
  setHotelDirectoryState: () => {},
};

const HotelDirectoryStore: StateCreator<HotelDirectoryState> = set => {
  return {
    ...initialState,

    //Hotel Directory context setter method
    setHotelDirectoryState: (fieldNamesArray: Array<string>, fieldsData: any, reset?: boolean) =>
      set(state => {
        const updatedState = searchFormHelperFunction(state, fieldNamesArray, fieldsData, reset);
        return updatedState;
      }),
  };
};

export const useHotelDirectoryStore = createAppStore(HotelDirectoryStore, {
  usePersistentStore: false,
});
