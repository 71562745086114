import { FC } from 'react';
import { Pill } from '../../atoms/Pill/index';
import { PillProps } from '../../atoms/Pill/Pill.types';
import { PillCollectionProps } from './PillCollection.types';
import { StyledPillCollection } from './PillCollection.styles';

export const PillCollection: FC<PillCollectionProps> = ({ pills, selectedPillValues, onChange, customClass }) => {
  return (
    <StyledPillCollection className={customClass}>
      {pills?.map((pill: PillProps, index: number) => {
        const {
          label,
          value,
          isActive,
          isDisabled,
          count,
          showIcon,
          iconClass,
          iconAriaLabel,
          ariaLabel,
          custom_click_track_value,
          buttonClassName,
          className,
          id,
        } = pill;
        return (
          <Pill
            key={index}
            label={label}
            ariaLabel={ariaLabel}
            value={value}
            isActive={isActive || selectedPillValues?.includes(value)}
            isDisabled={isDisabled}
            count={count}
            showIcon={showIcon}
            iconClass={iconClass}
            iconAriaLabel={iconAriaLabel}
            className={`pill ${className}`}
            onClick={value => onChange && onChange(value)}
            custom_click_track_value={custom_click_track_value}
            buttonClassName={buttonClassName}
            id={id}
          />
        );
      })}
    </StyledPillCollection>
  );
};
