/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { produce } from 'immer';
import type { SessionData } from './types';
import { StateStorage } from 'zustand/middleware';
import { getSessionStoreData, updateServerSession } from './session-utils';
import { createAppStore } from '@marriott/mi-store-utils';
import { getEnvProp } from '../helpers';

export type UserSessionActions = {
  updateSession: (data: SessionData) => void;
};

export type UserSessionState = {
  // stored user session attributes
  session: SessionData;
  syncedWithServer: boolean;
};

export type SessionStoreStr = {
  state: UserSessionState;
  version: number;
};

let serverData: SessionData | null = null;
const initalState: UserSessionState = {
  session: {},
  syncedWithServer: false,
};

export const serverSyncSessionStorage: StateStorage = {
  setItem(name, value) {
    const valueObj = JSON.parse(value) as SessionStoreStr;
    // add feature flag to disable push in future
    if (Number(serverData?.data?.lastUpdated) < Number(valueObj.state.session.data?.lastUpdated)) {
      // push to server
      updateServerSession({
        createOrUpdate: {
          ...valueObj.state.session.data,
        },
        name: 'SyncServerPush',
      });
    }

    window.sessionStorage.setItem(name, value);
  },
  getItem(name) {
    const sessionStr: SessionStoreStr = JSON.parse(
      window.sessionStorage.getItem(name) ||
        JSON.stringify({
          version: 0,
          state: {
            ...initalState,
          },
        })
    ) as SessionStoreStr;
    return JSON.stringify({
      ...sessionStr,
      state: {
        ...sessionStr.state,
        syncedWithServer: false,
      },
    });
  },
  removeItem(name) {
    window.sessionStorage.removeItem(name);
  },
};

// @ts-ignore - error TS2347: Untyped function calls may not accept type arguments.
export const useUserSessionStore = createAppStore<UserSessionState & UserSessionActions>(
  // @ts-ignore - error TS7006: Parameter 'set' implicitly has an 'any' type.
  set => ({
    updateSession: (data: SessionData) => {
      set(
        (state: UserSessionState) =>
          produce(state, (draft: UserSessionState) => {
            draft.session = {
              data: {
                ...state.session.data,
                ...data.data,
                lastUpdated: String(new Date().getTime()),
              },
            };
          }),
        false,
        // @ts-ignore - provide a action name for devtools
        'updateSession'
      );
    },
  }),
  {
    usePersistentStore: true,
    // skipHydration: getEnvProp('NEXT_PUBLIC_ENABLE_WEBSDK') !== 'true', // set to true for manually hydration store based on featured flag
    persistentStoreName: 'mi-session-store',
    devToolsName: 'mi-session-store',
    customStorage: serverSyncSessionStorage,
  }
);

(async () => {
  if (typeof window === 'undefined' || getEnvProp('NEXT_PUBLIC_ENABLE_WEBSDK') !== 'true') {
    return;
  }
  const browserDataStr = window.sessionStorage.getItem('mi-session-store');
  let browserData: SessionStoreStr = {} as SessionStoreStr;
  try {
    browserData = JSON.parse(browserDataStr || '{}') as SessionStoreStr;
  } catch (e) {
    // do something here
  }
  if (!serverData) {
    serverData = (await getSessionStoreData()) as SessionData;
    const browserTimeStamp = Number(browserData.state?.session?.data?.lastUpdated) || 0;
    const serverTimeStamp = Number(serverData.data?.lastUpdated) || 1;
    if (browserTimeStamp < serverTimeStamp) {
      useUserSessionStore.setState(
        {
          ...browserData.state,
          session: {
            ...serverData,
            data: {
              ...serverData.data,
              lastUpdated: String(new Date().getTime()),
            },
          },
          syncedWithServer: true,
        },
        true,
        'SessionServerSync'
      );
    }
  }
})();
