import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';

import { Heading } from '../../atoms/Heading';
import { Image } from '../../atoms/Image';
import { ButtonStyle, headingType, size } from '../../utils/enums/enums';
import { StyledCardSummaryContainer } from './CardSummary.styles';
import { CardSummaryProps } from './CardSummary.types';
import { Button, Link, Modal, RichText } from '@marriott/mi-ui-library';

const getButtonStyle = (ctaType: string, openInNewTab: boolean): string => {
  switch (ctaType) {
    case 'primaryButton':
      return openInNewTab ? ButtonStyle.PRIMARY_BUTTON_EXTERNAL : ButtonStyle.PRIMARY_BUTTON;
    case 'secondaryButton':
      return openInNewTab ? ButtonStyle.SECONDARY_BUTTON_EXTERNAL : ButtonStyle.SECONDARY_BUTTON;
    case 'tertiaryLink':
      return openInNewTab ? ButtonStyle.TERTIARY_BUTTON_EXTERNAL : ButtonStyle.TERTIARY_BUTTON;
    default:
      return '';
  }
};

export const CardSummary: FC<CardSummaryProps> = ({
  ctaLink = '',
  componentId,
  ctaLabel = '',
  trackingProperties,
  ctaType,
  addLinkInModal,
  fileReferenceImage,
  additionalLinkText = '',
  description,
  cardSummaryType,
  addLinkInNewTab = false,
  additionalLinkUrl = '',
  header,
  headerTag,
  openInNewTab = false,
  modalHeader = '',
  modalDescription = '',
  styleclass,
  customclass,
}) => {
  const [viewPort, setViewPort] = useState('Desktop');
  const [popupOpenState, setPopupOpenState] = useState<boolean>(false);
  const linkClassName = ['m-link pb-0 t-font-s additional-link-cta'];
  const isMobileView = viewPort === 'Mobile';

  const toggleModal = () => {
    setPopupOpenState(!popupOpenState);
  };

  const descriptionRef = useRef<HTMLDivElement>(null);

  const buttonTypeClasses = useMemo(() => getButtonStyle(ctaType || '', openInNewTab), [ctaType, openInNewTab]);

  const handleResize = () => {
    const xs = window.matchMedia('(max-width: 575px)');
    const md = window.matchMedia('(min-width: 576px) and (max-width: 991px)');
    const lg = window.matchMedia('(min-width: 992px)');
    if (xs?.matches) {
      setViewPort('Mobile');
    } else if (md?.matches) {
      setViewPort('Tablet');
    } else if (lg?.matches) {
      setViewPort('Desktop');
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const ImageContent = () => {
    return (
      <Image
        customClass={''}
        altText={fileReferenceImage?.altText}
        renditions={fileReferenceImage?.renditions}
        dynamic={fileReferenceImage?.dynamic}
        defaultImageURL={fileReferenceImage?.assetPath}
      />
    );
  };

  const LinkContent = () => {
    if (addLinkInModal || isMobileView || !(additionalLinkText && additionalLinkUrl)) return null;
    return (
      <div className="mt-2">
        <Link
          linkClassName={linkClassName}
          linkHref={additionalLinkUrl}
          target={addLinkInNewTab === true ? '_blank' : '_self'}
          rel={`${addLinkInNewTab ? 'noopener noreferrer' : ''}`}
        >
          {additionalLinkText}
        </Link>
      </div>
    );
  };

  const ModalContent = () => {
    if (!addLinkInModal || isMobileView || !additionalLinkText) return null;
    return (
      <div className={clsx('mt-2')}>
        <Link
          linkClassName={linkClassName}
          linkHref={'#'}
          callback={e => {
            e.preventDefault();
            toggleModal();
          }}
        >
          {additionalLinkText}
        </Link>
        <Modal show={popupOpenState}>
          <Modal.Header labelText={modalHeader} popupHeaderOnCLoseFunc={toggleModal} />
          <Modal.Body>
            <RichText componentId={`ctasummary-richtext-${componentId}`} text={modalDescription}></RichText>
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  const CTAButtonContent = () => {
    if (isMobileView) return null;
    return (
      <Button
        isLink={true}
        href={ctaLink}
        className={`cta-summary-btn ${buttonTypeClasses} ${ctaType !== 'tertiaryLink' ? ' m-button-s' : ''}`}
        target={openInNewTab === true ? '_blank' : '_self'}
        trackingProperties={trackingProperties}
        ariaLabel={ctaLabel}
      >
        {ctaLabel}
      </Button>
    );
  };

  const ChevronIcon = () => {
    if (!isMobileView) return null;
    return (
      <div className="cta-summary-chevron d-flex align-items-center">
        <span className={clsx('icon-l icon-arrow-right')}></span>
      </div>
    );
  };

  const getCardContent = () => {
    return (
      <>
        <ImageContent />
        <div className={clsx('flex-fill card-summary-content-section')}>
          <Heading
            customClass={clsx('mb-0', isMobileView ? 'm-ellipsis-2lines' : 'm-ellipsis-1line')}
            variation={headingType.subtitle}
            fontSize={isMobileView ? size.large : size.extraLarge}
            element={headerTag}
            dangerouslySet={header}
          />
          {description && (
            <RichText
              text={description}
              ref={descriptionRef}
              componentId={componentId + '-CardSummaryDescriptionText'}
              customClass={clsx('t-font-s', isMobileView ? 'm-ellipsis-1line' : 'm-ellipsis-2lines')}
            />
          )}
          <LinkContent />
          <ModalContent />
        </div>
        <CTAButtonContent />
        <ChevronIcon />
      </>
    );
  };

  return cardSummaryType === 'cta' ? (
    <StyledCardSummaryContainer
      data-component-name="m-common-cardsummary"
      className={clsx('d-flex', styleclass, customclass)}
    >
      {isMobileView ? (
        <Link
          linkClassName={['d-flex flex-fill']}
          linkHref={ctaLink}
          target={openInNewTab === true ? '_blank' : '_self'}
          trackingProperties={trackingProperties}
          ariaLabel={ctaLabel}
        >
          {getCardContent()}
        </Link>
      ) : (
        getCardContent()
      )}
    </StyledCardSummaryContainer>
  ) : null;
};
